import store from "@/store";
import helpers from "@/helpers";
import axios from "axios";
import { toRefs, computed, ref, onMounted, watch } from "@vue/composition-api";

let cancelToken;
const state = store.returnBook.state;
const filterState = store.returnBook.filters;
const mlState = computed(() => store.mainLayout.state || {});
const isLargeLoading = computed(() => !state.loadedAll && !state.data.length);
const noRecordsFound = computed(() => state.loadedAll && !state.data.length);
const isEmpty = computed(() => !state.data.length);

const loadBookTypes = () => {
  axios
    .get(`${process.env.VUE_APP_API}/book/type`)
    .then((response) => {
      filterState.searchByTypeFilters = response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    })
    .finally(() => (filterState.isBookTypesLoaded = true));
};

const loadSubLibraries = () => {
  axios
    .get(`${process.env.VUE_APP_API}/subLibraries`)
    .then((response) => {
      filterState.subLibraries = response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    })
    .finally(() => (filterState.isSubLibrariesLoaded = true));
};

const loadNextPage = (reset = false) => {
  if (reset) {
    state.data = [];
    state.loadedAll = false;
  }
  if ((!state.isLoading && !state.loadedAll) || reset) {
    state.isLoading = true;
    const alreadyLoadedCount = state.data.length;

    if (cancelToken) {
      cancelToken.cancel("Operation canceled due to new request.");
    }

    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source();
    const query = `?skip=${alreadyLoadedCount}&limit=${process.env.VUE_APP_ITEMS_PER_PAGE}${store.returnBook.filterQuery}`;
    axios
      .get(`${process.env.VUE_APP_API}/book${query}`, {
        cancelToken: cancelToken.token
      })
      .then((response) => {
        state.data.push(...response.data.data);
        state.loadedAll = !response.data.nextPage;
      })
      .finally(() => (state.isLoading = false))
      .catch((err) => {});
  }
};

watch(
  () => store.returnBook.filterQuery,
  (value, oldValue) => {
    if (value != oldValue) {
      loadNextPage(true);
    }
  }
);

watch(
  () => state.selectedBooks,
  (value, oldValue) => {
    if (oldValue.length > value.length) {
      const valueToRemove = helpers.arrDiff(oldValue, value)[0];
      const indexToRemove = state.selectedBooksDetails.findIndex(
        (book) => book._id === valueToRemove
      );
      if (indexToRemove >= 0) {
        state.selectedBooksDetails.splice(indexToRemove, 1);
      }
    } else {
      const valueToAdd = helpers.arrDiff(value, oldValue)[0];
      const bokkDetailToAdd = state.data.find((book) => {
        return book._id === valueToAdd;
      });
      if (bokkDetailToAdd) {
        state.selectedBooksDetails.push(bokkDetailToAdd);
      }
    }
  }
);

export default (props, { root }) => {
  const scrollable = ref(null);

  onMounted(() => {
    if (!filterState.isBookTypesLoaded) {
      loadBookTypes();
    }
    if (!filterState.isSubLibrariesLoaded && mlState) {
      loadSubLibraries();
    }
    helpers.vueBarEvents(scrollable.value, {
      onScrollToBottom: () => {
        loadNextPage();
      },
      scrollToBottomOffset: 323
    });
    if (!state.data.length) {
      loadNextPage();
    }
    state.resetReturnOn();
  });

  return {
    ...toRefs(state),
    selectedsubLibrary: computed(() => filterState.selectedsubLibrary),
    fromSubLibrary: computed(() => filterState.fromSubLibrary),
    isLargeLoading,
    scrollable,
    isEmpty,
    noRecordsFound,
    onMounted,
    loadNextPage
  };
};
