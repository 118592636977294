import {
  ref,
  computed,
  toRefs,
  watch,
  reactive,
  onMounted
} from "@vue/composition-api";
import store from "@/store";
import login from "./login";
import axios from "axios";
import helpers from "@/helpers";
import { districts } from "@/config";

let searchToken;
const state = store.loginLayout.state;
const usernameError = ref("");
const passwordError = ref("");
const showEye = ref(false);

const width = computed(() => {
  switch (store.app.getScreenSize) {
    case "xs":
      return 320;
    case "sm":
      return 500;
    case "md":
      return 500;
    case "lg":
      return 550;
    case "xl":
      return 550;
  }
});

const selectLibrary = (e) => {
  if (e?.name) {
    state.serchedLibrary = { typeSearchKey: state.typeSearchKey, ...e };
    const searchedKey = state.typeSearchKey;
    setTimeout(() => {
      state.typeSearchKey = searchedKey;
    }, 0);
  }
};

const searchQuery = computed(
  () =>
    `?searchTerm=${encodeURIComponent(state.typeSearchKey)}${
      state.searchDistrict ? `&district=${state.searchDistrict}` : ""
    }${state.searchLibrary ? `&library=${state.searchLibrary._id}` : ""}`
);

const searchBooks = () => {
  if (state.searchSelection?.name) {
    return;
  }
  if (searchToken) {
    searchToken.cancel("Operation canceled due to new request.");
  }
  state.searching = true;
  state.searchResults = [];
  searchToken = axios.CancelToken.source();
  axios
    .get(`${process.env.VUE_APP_API_NOAUTH}/books${searchQuery.value}`, {
      cancelToken: searchToken.token
    })
    .then((response) => {
      state.searchResults = response.data?.data;
      state.searchmenu = true;
    })
    .catch((error) => {
      return Promise.reject(error);
    })
    .finally(() => {
      state.searching = false;
    });
};

const getLibraries = () => {
  axios.get(`${process.env.VUE_APP_API_NOAUTH}/library`).then((response) => {
    state.libraries = response.data?.data;
  });
};

watch(
  () => reactive({ searchQuery }).searchQuery,
  () => {
    state.typeSearchKey?.length ? searchBooks() : (state.searchResults = []);
  }
);

export default (_, { root }) => {
  const form = ref(null);
  const usernameInput = ref(null);
  const passwordInput = ref(null);

  onMounted(() => {
    getLibraries();
  });

  const validate = () => {
    if (form.value.validate()) {
      attemptLogin(root.$route.meta.title);
    }
  };

  const nameRules = [
    (v) =>
      !!v ||
      (usernameError.value.length
        ? usernameError.value
        : "Username is required")
  ];
  const passwordRules = [
    (v) =>
      !!v ||
      (passwordError.value.length
        ? passwordError.value
        : "Password is required")
  ];

  const attemptLogin = (title) => {
    login.attemptLogin(
      ({ data }) => {
        const scope = data.scope;
        if (scope.includes("expired")) {
          root.$router.push("/account");
          store.app.showDialog(
            "Account expired",
            "Your account has been expired",
            "error"
          );
        }
        if (scope.includes("expiringsoon")) {
          root.$router.push("/account");
          store.app.showDialog(
            "Account expiring soon",
            "Your account is going to expire soon",
            "warning"
          );
        }
        state.userName = state.password = "";
        store.app.logIn();
        title && (document.title = title);
        helpers.localStorage.setItem("logged_in", true);
        helpers.localStorage.setItem("keep_signed_in", keepSignedIn);
      },
      (response) => {
        if (response === undefined) {
          return;
        }
        if (response?.data?.field == "password") {
          passwordError.value = response.data.message;
        } else if (response?.data?.field == "username") {
          usernameError.value = response.data.message;
        } else {
          store.app.showAlert("Some error occured!");
        }
      }
    );
  };

  watch(
    () => state.showLogin,
    (value) => {
      if (!value) {
        form.value.reset();
      }
    }
  );

  return {
    ...toRefs(state),
    valid: true,
    nameRules,
    passwordRules,
    form,
    usernameInput,
    passwordInput,
    validate,
    usernameError,
    passwordError,
    attemptLogin,
    showEye,
    selectLibrary,
    districts,
    width,
    scnSize: store.app.getScreenSize
  };
};
