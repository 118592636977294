import { reactive, toRefs, watch } from "@vue/composition-api";
import axios from "axios";
import store from "@/store";
import moment from "moment";

const state = reactive({
  isGenerating: false,
  isRecent: false,
  ext: ""
});
let checkIfGenerationCompleted;
const startGenerate = (api) => {
  if (!state.isGenerating) {
    state.isGenerating = true;
    axios
      .get(api, {
        timeout: 25000,
        onUploadProgress: (progressEvent) => {
          const percentage = (progressEvent.loaded / progressEvent.total) * 100;
        }
      })
      .then((response) => {
        checkIfGenerationCompleted && checkIfGenerationCompleted(false);
        window.location.href = response.data.data;
      })
      .finally(() => {
        state.isGenerating = false;
      })
      .catch((error) => {
        checkIfGenerationCompleted && checkIfGenerationCompleted(false);
        if (error.message === "timeout of 25000ms exceeded") {
          store.app.showDialog(
            "Notice!",
            "Report generation taking too long, please check after few minutes",
            "warning"
          );
        } else {
          store.app.showAlert("Some error occured!");
        }
      });
  }
};

export default (props) => {
  watch(
    () => props.value,
    (value) => {
      if (value) {
        state.ext = store.reports.reportWindowFileName.split(".").pop();
        checkIfGenerationCompleted = props.checkIfGenerationCompleted;
      }
    }
  );

  watch(
    () => props.s3Info,
    (value) => {
      if (value) {
        state.isRecent =
          value?.s3FileHeadInfo?.LastModified &&
          moment(store.app.getTime).diff(
            value?.s3FileHeadInfo?.LastModified,
            "minutes"
          ) <= 15;
      }
    }
  );

  return {
    ...toRefs(state),
    startGenerate
  };
};
