const pascalCase = (string) => {
  if (!string) {
    return string;
  }
  return string
    .replace(/(\w)(\w*)/g, function(g0, g1, g2) {
      return g1.toUpperCase() + g2.toLowerCase();
    })
    .replace(/\s\s+/g, " ")
    .trim();
};

function kebabCase(str) {
  str = str.toLowerCase();
  str = str.replace(/[^a-z0-9]+/g, "-");
  str = str.replace(/^-|-$/g, "");
  return str;
}

const removeMultipleSpaces = (string) => {
  return string.replace(/\s\s+/g, " ").trim();
};

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const passwordCriteriaMet = (pwd) => {
  const pwdFilter = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9_])"
  );
  return pwdFilter.test(pwd) && pwd.length <= 20 && pwd.length > 8;
};

const usernameCriteriaMet = (un) => {
  const unFilter = new RegExp("^[A-Za-z0-9_.]+$");
  return un && unFilter.test(un) && un.length >= 5;
};

const isEmpty = (s) => !s || s.trim().length === 0;

export default {
  pascalCase,
  kebabCase,
  validateEmail,
  passwordCriteriaMet,
  removeMultipleSpaces,
  usernameCriteriaMet,
  isEmpty
};
