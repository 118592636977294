import download from "downloadjs";
import store from "@/store";
import axios from "axios";
import Moment from "moment";

const getLibraryData = (loadinText, subLibrary, updateState, notFound) => {
  loadinText && store.app.showLoader(loadinText);
  axios
    .get(
      `${process.env.VUE_APP_API}/libraryBasicData/${
        subLibrary ? subLibrary : ""
      }`
    )
    .then((response) => {
      const state = updateState || store.mainLayout.state;
      state.libraryBasicDetailsLoaded = true;
      state.libraryName = response.data.name;
      state.libraryPhone = response.data.phone;
      state.libraryEmail = response.data.email;
      state.libraryAddress = response.data.address;
      state.libraryRegNo = response.data.regNo;
      state.libraryParent = response.data.parent;
      state.libraryDistrict = response.data.district;
      state.libraryPin = response.data.pin;
      state.isFreeTrail = response.data.isFreeTrail;
      state.user = response.data.user;
      state.librarySubscription = response.data.librarySubscription;
      state.libraryHasLogo = response.data.hasLogo;
      state.bUploadInProgress =
        response.data?.settings?.bUploadInProgress || null;
      state.libraryLogoUrl = `${process.env.VUE_APP_PUBLIC_BUCKET}/library-logos/${response.data._id}_thumb.jpg?u=${response.data.updatedAt}`;
    })
    .catch((error) => {
      if (error.response.status === 400 || error.response.status === 404) {
        notFound && notFound();
      } else {
        return Promise.reject(error);
      }
    })
    .finally(() => {
      loadinText && store.app.hideLoader();
    });
};

const vueBarEvents = (ele, events) => {
  const scrollPane = ele.querySelector(".vb-content");
  if (events.onScroll) {
    scrollPane.addEventListener("scroll", events.onScroll);
  }
  if (events.onScrollToBottom) {
    scrollPane.addEventListener("scroll", () => {
      const offset = events.scrollToBottomOffset
        ? events.scrollToBottomOffset
        : 0;
      if (
        scrollPane.scrollHeight - scrollPane.offsetHeight - offset <=
        Math.ceil(scrollPane.scrollTop)
      ) {
        events.onScrollToBottom();
      }
    });
  }
};

const convertToIst = (currentTime) => {
  const currentOffset = currentTime.getTimezoneOffset();

  const ISTOffset = 330; // IST offset UTC +5:30

  const ISTTime = new Date(
    currentTime.getTime() + (ISTOffset + currentOffset) * 60000
  );

  return ISTTime;
};

function arrDiff(a1, a2) {
  var a = [],
    diff = [];

  for (var i = 0; i < a1.length; i++) {
    a[a1[i]] = true;
  }

  for (var i = 0; i < a2.length; i++) {
    if (a[a2[i]]) {
      delete a[a2[i]];
    } else {
      a[a2[i]] = true;
    }
  }

  for (var k in a) {
    diff.push(k);
  }

  return diff;
}

function monthName(mon) {
  return [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ][mon - 1];
}

function dataURItoBlob(dataURI) {
  var byteString = atob(dataURI.split(",")[1]);
  var mimeString = dataURI
    .split(",")[0]
    .split(":")[1]
    .split(";")[0];
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  var blob = new Blob([ab], { type: mimeString });
  return blob;
}

function downloadFromDataUri(dataUri, fileName, mimeType) {
  download(dataURItoBlob(dataUri), fileName, mimeType);
}

function downloadFromBlob(blob, fileName, mimeType) {
  download(blob, fileName, mimeType);
}

function vueAddTimeFomats(Vue) {
  Vue.filter("formatDate", function(value) {
    if (value) {
      return Moment(value).format("DD-MM-YYYY");
    }
  });

  Vue.filter("formatDateUtcFix", function(value) {
    if (value) {
      if (process.env.NODE_ENV === "development") {
        return Moment(value).format("DD-MM-YYYY");
      } else {
        return Moment(value)
          .utcOffset("-00:00")
          .format("DD-MM-YYYY");
      }
    }
  });

  Vue.filter("formatDateMonthAndYear", function(value) {
    if (value) {
      return Moment(value).format("MMM, YYYY");
    }
  });

  Vue.filter("formatTime", function(value) {
    if (value) {
      if (process.env.NODE_ENV === "development") {
        return Moment(value).format("hh:mm A");
      } else {
        return Moment(value)
          .utcOffset("-00:00")
          .format("hh:mm A");
      }
    }
  });

  Vue.filter("formatDateTime", function(value) {
    if (value) {
      if (process.env.NODE_ENV === "development") {
        return Moment(value).format("DD-MM-YYYY [at] hh:mm A");
      } else {
        return Moment(value)
          .utcOffset("-00:00")
          .format("DD-MM-YYYY [at] hh:mm A");
      }
    }
  });
}

const setWindowValues = (values) => {
  for (const key in values) {
    window[key] = values[key];
  }
};

const getBase64FromFile = (file, onLoad, Onerror) => {
  var reader = new FileReader();
  reader.readAsDataURL(file);
  onLoad && (reader.onload = () => onLoad(reader.result));
  Onerror && (reader.onerror = Onerror);
};

const getObjectCopy = (obj) => JSON.parse(JSON.stringify(obj));

const printElement = (elementId, printTitle = "Print", width = 450) => {
  var oIframe = document.getElementById("print-iframe");
  var oContent = document.getElementById(elementId).innerHTML;
  var oDoc = oIframe.contentWindow || oIframe.contentDocument;
  if (oDoc.document) oDoc = oDoc.document;
  oDoc.write(`<head><title>${printTitle}</title><style>
  body {
    width: ${width}px;
    margin: 0 auto;
    padding-top: 50px;
    font-family: "Roboto";
  }
  .text-center {
    text-align: center;
  }
  h2,h4,h5 {
    margin: 0;
  }
  .pb-4 {
    padding-top: 7px;
    padding-bottom: 14px;
  }
  .pb-1 {
    margin-top: 5px;
  }
  .pb-1.fix {
    margin-top: 1px;
    margin-bottom: -3px;
  }
  </style>`);
  oDoc.write('</head><body onload="this.focus(); this.print();">');
  oDoc.write(oContent + "</body>");
  var paras = oDoc.getElementsByClassName("ignore-from-print");
  while (paras[0]) {
    paras[0].parentNode.removeChild(paras[0]);
  }
  oDoc.close();
};

export default {
  printElement,
  vueBarEvents,
  vueAddTimeFomats,
  convertToIst,
  getObjectCopy,
  arrDiff,
  monthName,
  downloadFromDataUri,
  downloadFromBlob,
  getLibraryData,
  setWindowValues,
  getBase64FromFile
};
