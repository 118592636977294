import Vue from "vue";
import VueRouter from "vue-router";
import { lazy } from "vue-async-manager";
import routeChangeEvents from "./routeChangeEvents";
Vue.use(VueRouter);

const routes = [
  {
    path: "/sign-up",
    name: "sign-up",
    meta: {
      title: "Sign Up",
      public: true,
      disableOnLogin: true
    },
    component: lazy(() =>
      import(/* webpackChunkName: "sign-up" */ "@/views/Register.vue")
    )
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    meta: {
      title: "Forgot Password",
      public: true,
      disableOnLogin: true
    },
    component: lazy(() =>
      import(
        /* webpackChunkName: "forgot-password" */ "@/views/ForgotPassword.vue"
      )
    )
  },
  {
    path: "/reset-password/:resetId",
    name: "reset-password",
    meta: {
      title: "Reset Password",
      public: true,
      disableOnLogin: true
    },
    component: lazy(() =>
      import(
        /* webpackChunkName: "reset-password" */ "@/views/ResetPassword.vue"
      )
    )
  },
  {
    path: "/privacy",
    name: "privacy",
    meta: {
      title: "Privacy",
      public: true
    },
    component: lazy(() =>
      import(/* webpackChunkName: "privacy" */ "@/views/Privacy.vue")
    )
  },
  {
    path: "/pricing",
    name: "pricing",
    meta: {
      title: "Pricing",
      public: true
    },
    component: lazy(() =>
      import(/* webpackChunkName: "pricing" */ "@/views/Pricing.vue")
    )
  },
  {
    path: "/shipping",
    name: "shipping",
    meta: {
      title: "Shipping",
      public: true
    },
    component: lazy(() =>
      import(/* webpackChunkName: "shipping" */ "@/views/Shipping.vue")
    )
  },
  {
    path: "/refund",
    name: "refund",
    meta: {
      title: "Refund",
      public: true
    },
    component: lazy(() =>
      import(/* webpackChunkName: "refund" */ "@/views/Refund.vue")
    )
  },
  {
    path: "/terms",
    name: "terms",
    meta: {
      title: "Terms",
      public: true
    },
    component: lazy(() =>
      import(/* webpackChunkName: "terms" */ "@/views/Terms.vue")
    )
  },
  {
    path: "/contact",
    name: "contact",
    meta: {
      title: "Contact Us",
      public: true
    },
    component: lazy(() =>
      import(/* webpackChunkName: "contact" */ "@/views/Contact.vue")
    )
  },
  {
    path: "/",
    name: "dashboard",
    meta: {
      bigHeader: true,
      title: "Dashboard"
    },
    component: lazy(() =>
      import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard.vue")
    )
  },
  {
    path: "/billing",
    name: "billing",
    meta: {
      title: "Billing"
    },
    component: lazy(() =>
      import(/* webpackChunkName: "billing" */ "@/views/Billing.vue")
    )
  },
  {
    path: "/books",
    name: "books",
    meta: {
      title: "Books"
    },
    component: lazy(() =>
      import(/* webpackChunkName: "books" */ "@/views/Books.vue")
    )
  },
  {
    path: "/logs",
    name: "logs",
    meta: {
      title: "Logs"
    },
    component: lazy(() =>
      import(/* webpackChunkName: "logs" */ "@/views/Logs.vue")
    )
  },
  {
    path: "/reports",
    name: "reports",
    meta: {
      title: "Reports"
    },
    component: lazy(() =>
      import(/* webpackChunkName: "reports" */ "@/views/Reports.vue")
    )
  },
  {
    path: "/books/addNew",
    name: "new_book",
    meta: {
      title: "Add new book"
    },
    component: lazy(() =>
      import(/* webpackChunkName: "singleBook" */ "@/views/SingleBook.vue")
    )
  },
  {
    path: "/books/:id",
    name: "single_book",
    meta: {
      title: "Book details"
    },
    component: lazy(() =>
      import(/* webpackChunkName: "singleBook" */ "@/views/SingleBook.vue")
    )
  },
  {
    path: "/distribute",
    name: "distribute",
    meta: {
      title: "Distribute"
    },
    component: lazy(() =>
      import(/* webpackChunkName: "distribute" */ "@/views/Distribute.vue")
    )
  },
  {
    path: "/return",
    name: "returnBook",
    meta: {
      title: "Return"
    },
    component: lazy(() =>
      import(/* webpackChunkName: "return" */ "@/views/Return.vue")
    )
  },
  {
    path: "/members",
    name: "members",
    meta: {
      title: "Members"
    },
    component: lazy(() =>
      import(/* webpackChunkName: "members" */ "@/views/Members.vue")
    )
  },
  {
    path: "/member-bills",
    name: "member-bills",
    meta: {
      title: "Member Bills"
    },
    component: lazy(() =>
      import(/* webpackChunkName: "member-bills" */ "@/views/MemberBills.vue")
    )
  },
  {
    path: "/members/addNew",
    name: "new_member",
    meta: {
      title: "Add member details"
    },
    component: lazy(() =>
      import(/* webpackChunkName: "singleMember" */ "@/views/SingleMember.vue")
    )
  },
  {
    path: "/members/:id",
    name: "single_member",
    meta: {
      title: "Member details"
    },
    component: lazy(() =>
      import(/* webpackChunkName: "singleMember" */ "@/views/SingleMember.vue")
    )
  },
  {
    path: "/bulkUpload",
    name: "bulk_upload",
    meta: {
      title: "Bulk Upload"
    },
    component: lazy(() =>
      import(/* webpackChunkName: "bulkUpload" */ "@/views/BulkUpload.vue")
    )
  },
  {
    path: "/account",
    name: "account",
    meta: {
      title: "Account"
    },
    component: lazy(() =>
      import(/* webpackChunkName: "settings" */ "@/views/Settings.vue")
    )
  },
  {
    path: "/sub-libraries/:id",
    name: "sub-libraries-edit",
    meta: {
      title: "Edit Sub Library"
    },
    component: lazy(() =>
      import(
        /* webpackChunkName: "sub-libraries-edit" */ "@/views/Settings.vue"
      )
    )
  },
  {
    path: "/users",
    name: "users",
    meta: {
      title: "Users"
    },
    component: lazy(() =>
      import(/* webpackChunkName: "users" */ "@/views/Users.vue")
    )
  },
  {
    path: "/sub-libraries",
    name: "sub-libraries",
    meta: {
      title: "Sub Libraries"
    },
    component: lazy(() =>
      import(/* webpackChunkName: "sub-libraries" */ "@/views/SubLibraries.vue")
    )
  },
  {
    path: "*",
    name: "not-found",
    meta: {
      title: "Page not found",
      public: true
    },
    component: lazy(() =>
      import(/* webpackChunkName: "notFound" */ "@/views/NotFound.vue")
    )
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  routeChangeEvents(to, from);
  next();
});

export default router;
