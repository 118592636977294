import { reactive } from "@vue/composition-api";

const state = reactive({
  showReportWindow: false,
  reportWindowTitle: "",
  reportWindowApi: "",
  reportWindowFileName: "",
  reportWindowS3Info: null,
});

export default state;
