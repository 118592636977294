<template>
  <div class="public-page-wrap">
    <v-app v-if="disableOnLogin && isLoggedIn" class="not-allowed">
      <div
        class="d-flex justify-center flex-column align-center flex-grow-1 v-subheader theme--light pb-15"
      >
        {{ pageTitle || "This" }} page is not accessible while you are logged
        in.
        <br />
        <router-link to="/">Back to dashboard</router-link>
      </div>
    </v-app>
    <div v-else :class="['inner', pageName]" :style="{ height }">
      <Suspense :delay="200">
        <MainPageSkeltonLoader slot="fallback" />
        <router-view></router-view>
      </Suspense>
    </div>
    <div class="footer">
      <router-link to="/privacy">Privacy</router-link>
      |
      <router-link to="/terms">Terms</router-link>
      |
      <router-link to="/refund">Refund</router-link>
      |
      <router-link to="/contact">Contact Us</router-link>
      |
      <router-link to="/">{{ isLoggedIn ? "Dashboard" : "Home" }}</router-link>
    </div>
  </div>
</template>

<script>
import MainPageSkeltonLoader from "@/components/MainPageSkeltonLoader";
import { ref } from "@vue/composition-api";
import store from "@/store";

const height = ref(0);

const resized = () => (height.value = `${window.innerHeight - 30}px`);
export default {
  components: { MainPageSkeltonLoader },
  computed: {
    isLoggedIn() {
      return store.app.isLoggedIn;
    },
    pageName() {
      return this.$route.name;
    },
    pageTitle() {
      return this.$route.meta?.title;
    },
    disableOnLogin() {
      return this.$route.meta?.disableOnLogin ? true : false;
    }
  },
  setup() {
    return {
      height
    };
  },
  mounted() {
    resized();
    window.addEventListener("resize", resized);
  }
};
</script>

<style lang="scss" scoped>
.public-page-wrap {
  height: 100vh;
  overflow: hidden;
  &::after {
    pointer-events: none;
    content: "";
    position: absolute;
    left: 0;
    right: 50%;
    top: 0;
    bottom: 0;
    background-size: cover;
    background-position: left;
    height: 100vh;
    z-index: 6;
    background-image: url("~@/assets/backgrounds/layered-waves-haikei.svg");
  }
  .inner {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 10;
    &.not-found,
    &.forgot-password,
    &.reset-password {
      height: auto !important;
      ::v-deep .v-application--wrap {
        min-height: auto;
        height: calc(100vh - 80px);
      }
    }
  }
}
.footer {
  background: linear-gradient(45deg, #0899ec, #15b998);
  z-index: 3;
  color: #fff;
  font-size: 13px;
  height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  a {
    color: #fff;
    padding: 0 5px;
  }
}
.v-application.not-allowed {
  background-color: transparent;
}
</style>
