import { watch, toRefs, computed } from "@vue/composition-api";
import store from "@/store";
import axios from "axios";
import Moment from "moment";
import helpers from "@/helpers";
import config from "@/config";

const state = store.singleMember.state;
let router = null;

watch(
  () => state.signPasscodeDialog,
  value => {
    if (!value) {
      state.signPasscodeField = '';
    }
  }
);

watch(
  () => state.memberData,
  (newData, oldData) => {
    if (!oldData || newData.name != oldData.name) {
      changeTitle();
    }
  }
);

watch(
  () => state.editModeMemberData?.dateOfBirth,
  (newData) => {
    if (newData !== "") {
      const age = Moment(store.app.getTime).diff(
        Moment(newData, "YYYY-MM-DD"),
        "years"
      );
      state.editModeMemberData.age = age;
    }
  }
);

const dobTextCleared = (e) => {
  state.editModeMemberData.dateOfBirth = "";
  state.editModeMemberData.age = "";
};

const changeTitle = () => {
  const currentTitle = store.app.getRoute.current.meta.title;
  store.app.setTitle(currentTitle + `: ${state.memberData.name}`);
};

const showFeeWindow = () => {
  let memberState = store.memberFeeWindow.state;
  memberState.member = { ...state.memberData };
  memberState.showWindow = true;
};

const loadMemberData = (id) => {
  state.loading = true;
  state.newMember = false;
  axios
    .get(`${process.env.VUE_APP_API}/member/${id}`)
    .then((response) => {
      state.memberData = response.data;
      state.editModeMemberData = createEditModeMemberdata({ ...response.data });
      state.isEditMode = false;
      changeTitle();
    })
    .catch((error) => {
      if (error.response.status === 400 || error.response.status === 404) {
        router.push("/404");
      } else {
        return Promise.reject(error);
      }
    })
    .finally(() => {
      state.loading = false;
    });
};

const createEditModeMemberdata = (data) => {
  data.age = calculateAge(data.dateOfBirth);
  data.active = data.active ? 1 : 0;
  data.dateOfJoin = Moment(data.dateOfJoin).format("YYYY-MM-DD");
  if (data.dateOfBirth && data.dateOfBirth.length) {
    data.dateOfBirth = Moment(data.dateOfBirth).format("YYYY-MM-DD");
  }
  if (data.dateRemoved && data.dateRemoved.length) {
    data.dateRemoved = Moment(data.dateRemoved).format("YYYY-MM-DD");
  }
  data.dateRemovedMenu =
    data.dateRemoved && data.dateRemoved.length
      ? Moment(data.dateRemoved).format("YYYY-MM-DD")
      : "";
  return data;
};

const dataReadyForUpdate = computed(() => {
  const modifiedData = state.editModeMemberData;
  const data = {
    name: modifiedData.name.trim(),
    gender: modifiedData.gender,
    address: modifiedData.address.trim(),
    phone: modifiedData.phone
      ? modifiedData.phone.trim().replace(/ /g, "")
      : "",
    phone2: modifiedData.phone2
      ? modifiedData.phone2.trim().replace(/ /g, "")
      : "",
    gaurdian: modifiedData.gaurdian ? modifiedData.gaurdian.trim() : "",
    job: modifiedData.job ? modifiedData.job.trim() : "",
    email: modifiedData.email ? modifiedData.email.trim() : "",
    bloodType: modifiedData.bloodType ? modifiedData.bloodType.trim() : "",
    active: modifiedData.active == 1 ? true : false,
    removedReason: modifiedData.removedReason
      ? modifiedData.removedReason.trim()
      : "",
    dateOfJoin: Moment(modifiedData.dateOfJoin, "YYYY-MM-DD").format(
      "DD-MM-YYYY"
    )
  };

  if (modifiedData.dateOfBirth && modifiedData.dateOfBirth.length) {
    data["dateOfBirth"] = Moment(modifiedData.dateOfBirth, "YYYY-MM-DD").format(
      "DD-MM-YYYY"
    );
  }

  if (modifiedData.active !== 1) {
    if (modifiedData.dateRemoved && modifiedData.dateRemoved.length) {
      data["dateRemoved"] = Moment(
        modifiedData.dateRemoved,
        "YYYY-MM-DD"
      ).format("DD-MM-YYYY");
    }
    if (modifiedData.removedReason && modifiedData.removedReason.length) {
      data["removedReason"] = modifiedData.removedReason;
    }
  }

  if (state.newMember) {
    data["membershipPlan"] = state.membershipPlan;
  }

  return data;
});

const hasChanged = computed(() => {
  const data = state.memberData;
  const modifiedData = state.editModeMemberData;
  if (data.name.trim() != modifiedData.name.trim()) {
    return true;
  }
  if (data.gender != modifiedData.gender) {
    return true;
  }
  if (data.address.trim() != modifiedData.address.trim()) {
    return true;
  }
  if (data?.email?.trim() != modifiedData?.email?.trim()) {
    return true;
  }
  if (data?.gaurdian?.trim() != modifiedData?.gaurdian?.trim()) {
    return true;
  }
  if (data?.job?.trim() != modifiedData?.job?.trim()) {
    return true;
  }
  if (data?.bloodType?.trim() != modifiedData?.bloodType?.trim()) {
    return true;
  }
  if (data.removedReason != modifiedData.removedReason) {
    return true;
  }
  if (data.phone?.replace(/ /g, "") != modifiedData.phone?.replace(/ /g, "")) {
    return true;
  }
  if (
    data.phone2?.replace(/ /g, "") != modifiedData.phone2?.replace(/ /g, "")
  ) {
    return true;
  }
  if ((data.active ? 1 : 0) != modifiedData.active) {
    return true;
  }
  if (
    Moment(data.dateOfJoin).format("DD-MM-YYYY") !=
    Moment(modifiedData.dateOfJoin, "YYYY-MM-DD").format("DD-MM-YYYY")
  ) {
    return true;
  }
  if (
    Moment(data.dateOfBirth).format("DD-MM-YYYY") !=
    Moment(modifiedData.dateOfBirth, "YYYY-MM-DD").format("DD-MM-YYYY")
  ) {
    return true;
  }
  if (
    Moment(data.dateRemoved).format("DD-MM-YYYY") !=
    Moment(modifiedData.dateRemoved, "YYYY-MM-DD").format("DD-MM-YYYY")
  ) {
    return true;
  }
  return false;
});

const updateData = () => {
  if (!state.form.validate()) {
    store.app.showAlert("Please enter all required fields.");
    return;
  }
  store.app.showLoader("Updating...");
  axios
    .put(
      `${process.env.VUE_APP_API}/member/${state.memberData._id}`,
      dataReadyForUpdate.value
    )
    .finally(() => {
      store.app.hideLoader();
      store.app.showAlert("Data updated successfully.");
      state.memberData = { ...state.editModeMemberData };
      state.isEditMode = false;
      resetMemberPages();
    })
    .catch((error) => {
      store.app.showAlert("Some error occured.");
      return Promise.reject(error);
    });
};

const gotoNewMemberPage = () => {
  router.push({ name: "new_member" });
};

const saveNewData = () => {
  if (!state.form.validate()) {
    store.app.showAlert("Please enter all required fields.");
    return;
  }
  store.app.showLoader("Saving...");
  axios
    .post(`${process.env.VUE_APP_API}/member`, dataReadyForUpdate.value)
    .then((response) => {
      store.app.showAlert("Member saved successfully.");
      resetMemberPages();
      router.push({ name: "single_member", params: { id: response.data._id } });
      state.signPasscodeDialog = true;
    })
    .catch((error) => {
      store.app.showAlert("Some error occured.");
      return Promise.reject(error);
    })
    .finally(() => store.app.hideLoader());
};

const loadNewMemberData = () => {
  state.loading = false;
  state.newMember = true;
  state.membershipPlan = null;
  loadMemberClasses();
  state.memberData = state.editModeMemberData = {
    name: "",
    gender: "",
    address: "",
    phone: "",
    phone2: "",
    gaurdian: "",
    job: "",
    email: "",
    bloodType: "",
    dateOfJoin: "",
    dateOfBirth: "",
    active: 1,
    dateRemoved: "",
    removedReason: ""
  };
};

const goBackWard = () => {
  router.go(-1);
};

const loadMemberClasses = () => {
  state.membershipPlansLoading = true;
  axios
    .get(`${process.env.VUE_APP_API}/member/membershipPlan`)
    .then((response) => {
      state.membershipPlans = response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    })
    .finally(() => (state.membershipPlansLoading = false));
};

const resetMemberPages = () => {
  store.members.state.loadedAll = false;
  store.members.state.data = [];
};

const calculateAge = (dob) => {
  return Moment(store.app.getTime).diff(dob, "years");
};

const loadNextNumber = () => {
  state.nextNumber = "";
  state.nextNumberLoading = true;
  axios
    .get(`${process.env.VUE_APP_API}/member/nextNumber`)
    .then((response) => {
      state.nextNumber = response.data.next;
    })
    .finally(() => {
      state.nextNumberLoading = false;
    });
};

const updateSignPasscode = () => {
  store.app.showLoader("Updating...");
  axios
    .patch(
      `${process.env.VUE_APP_API}/member/signPasscode/${state.memberData._id}`,
      {
        passcode: state.signPasscodeField
      }
    )
    .finally(() => {
      store.app.hideLoader();
      store.app.showDialog("Success!", "Passcode saved successfully.", "success");
      state.signPasscodeDialog = false;
    })
    .catch((error) => {
      store.app.showAlert("Some error occured.");
      return Promise.reject(error);
    });
}

export default (props, { root }) => {
  store.memberFeeWindow.state.showWindow = false;
  loadNextNumber();
  router = root._router;
  if (root._route.params.id) {
    loadMemberData(root._route.params.id);
  } else {
    loadNewMemberData();
  }

  const genders = [
    { label: "Male", value: "m" },
    { label: "Female", value: "f" },
    { label: "Other", value: "o" }
  ];

  const ageKeyUp = (e) => {
    const age = e.target.value;
    const currentDob = state.editModeMemberData.dateOfBirth;
    const today = Moment(store.app.getTime);
    if (currentDob !== "") {
      const dob = Moment(currentDob, "YYYY-MM-DD");
      today.set("date", dob.get("date"));
      today.set("month", dob.get("month"));
    } else {
      today.set("date", 1);
      today.set("month", 0);
    }
    if (age === "") {
      state.editModeMemberData.dateOfBirth = "";
    } else {
      let calculatedDob = today
        .subtract(parseInt(age), "years")
        .format("YYYY-MM-DD");
      const offsetedAge = Moment(store.app.getTime).diff(
        Moment(calculatedDob, "YYYY-MM-DD"),
        "years"
      );
      const ageOffset = parseInt(age) - offsetedAge;
      if (ageOffset) {
        calculatedDob = Moment(calculatedDob, "YYYY-MM-DD")
          .add(-ageOffset, "years")
          .format("YYYY-MM-DD");
      }
      if (age.length <= 3) {
        state.editModeMemberData.dateOfBirth = calculatedDob;
      }
    }
  };

  const maxDob = computed(() => {
    if (store.app.getTime) {
      return store.app.getTime.toISOString();
    } else {
      return new Date().toISOString();
    }
  });

  return {
    ...toRefs(state),
    saveNewData,
    genderLabels: { f: "Female", m: "Male", o: "Other" },
    goBackWard,
    validateEmail: helpers.string.validateEmail,
    bloodTypes: config.bloodTypes,
    maxDob,
    ageKeyUp,
    isBackPossible: store.app.isBackPossible,
    hasChanged,
    updateData,
    gotoNewMemberPage,
    showFeeWindow,
    calculateAge,
    dobTextCleared,
    genders,
    formatDate: (date) => {
      if (!date) {
        return "";
      }
      return Moment(date, "YYYY-MM-DD").format("DD-MM-YYYY");
    },
    updateSignPasscode
  };
};
