import store from "@/store";
import helpers from "@/helpers";
import axios from "axios";
import { toRefs, computed, ref, onMounted, watch } from "@vue/composition-api";

let cancelToken;
const state = store.books.state;
const filterState = store.books.filters;
const isLargeLoading = computed(() => !state.loadedAll && !state.data.length);
const noRecordsFound = computed(() => state.loadedAll && !state.data.length);
const isEmpty = computed(() => !state.data.length);

const loadBookTypes = () => {
  axios
    .get(`${process.env.VUE_APP_API}/book/type`)
    .then((response) => {
      filterState.searchByTypeFilters = response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    })
    .finally(() => (filterState.isBookTypesLoaded = true));
};

const loadNextPage = (reset = false) => {
  if (reset) {
    state.data = [];
    state.loadedAll = false;
  }
  if ((!state.isLoading && !state.loadedAll) || reset) {
    state.isLoading = true;
    const alreadyLoadedCount = state.data.length;

    if (cancelToken) {
      cancelToken.cancel("Operation canceled due to new request.");
    }

    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source();
    const query = `?showAll=true&skip=${alreadyLoadedCount}&limit=${process.env.VUE_APP_ITEMS_PER_PAGE}${store.books.filterQuery}`;
    axios
      .get(`${process.env.VUE_APP_API}/book${query}`, {
        cancelToken: cancelToken.token
      })
      .then((response) => {
        state.data.push(...response.data.data);
        state.loadedAll = !response.data.nextPage;
      })
      .finally(() => (state.isLoading = false))
      .catch((error) => {
        return Promise.reject(error);
      });
  }
};

watch(
  () => store.books.filterQuery,
  (value, oldValue) => {
    if (value != oldValue) {
      loadNextPage(true);
    }
  }
);

export default (props, { root }) => {
  const scrollable = ref(null);
  state.showQrCode = false;

  onMounted(() => {
    if (!filterState.isBookTypesLoaded) {
      loadBookTypes();
    }
    helpers.vueBarEvents(scrollable.value, {
      onScrollToBottom: () => {
        loadNextPage();
      },
      scrollToBottomOffset: 380
    });
    if (!store.books.state.data.length) {
      loadNextPage();
    }
  });

  return {
    ...toRefs(store.books.state),
    isLargeLoading,
    scrollable,
    isEmpty,
    noRecordsFound,
    onMounted,
    loadNextPage
  };
};
