import { ref, reactive } from "@vue/composition-api";

const state = reactive({
  showQrCode: false,
  qrCodeBook: null,
  showAddBookType: false,
  bookData: null,
  editModeBookData: null,
  isEditMode: false,
  loading: false,
  dateAddedMenu: false,
  invoiceDateMenu: false,
  dateRemovedMenu: false,
  bookRemoved: 0,
  showAddBookModal: false,
  formValid: true,
  newBook: false,
  nextNumberLoading: true,
  nextNumber: "",
  isCustomCategory: false,
  form: ref(null)
});

export default reactive({
  state
});
