import store from "@/store";
import helpers from "@/helpers";
import axios from "axios";
import { toRefs, watch, computed, onMounted } from "@vue/composition-api";

let lastTime = new Date().getTime();
let interval;
const getServerTime = () => {
  const timeBeforeSend = new Date();
  axios.get(`${process.env.VUE_APP_API_NOAUTH}/time`).then((response) => {
    const timeAfterSend = new Date();
    const timeFromServer = new Date(response.data.time);
    const diff = timeAfterSend - timeBeforeSend;
    const timeToSet = new Date(timeFromServer.getTime() - diff / 2);
    store.app.setPricing(response.data.pricing);
    store.app.setTime(helpers.convertToIst(timeToSet));
    interval = setInterval(() => {
      var currentTime = new Date().getTime();
      if (currentTime > lastTime + 2000 * 2) {
        if (document.hidden) {
          return;
        }
        lastTime = currentTime;
        clearInterval(interval);
        getServerTime();
      }
      lastTime = currentTime;
      store.app.setTime(new Date(store.app.getTime.getTime() + 1000));
    }, 1000);
  });
};

export default (props, { root }) => {
  getServerTime();
  store.app.setScreenSize(computed(() => root.$vuetify.breakpoint.name));

  const isPublicPage = computed(() => root.$route.meta?.public === true);

  watch(
    () => store.app.getTitle,
    (title) => {
      document.title = title;
    }
  );

  onMounted(() => {
    addEventListener("storage", (event) => {
      if (event.key === "logged_in") {
        if (!event.newValue && store.app.isLoggedIn) {
          window.location.reload();
        }
        if (event.newValue && !store.app.isLoggedIn) {
          window.location.reload();
        }
      }
    });
  });

  if (!store.app.isLoggedIn) {
    const isLoggedIn = helpers.localStorage.getItem("logged_in");
    if (isLoggedIn) {
      store.app.logIn();
    }
  }
  return {
    ...toRefs(store.app),
    isPublicPage
  };
};
