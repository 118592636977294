import store from "@/store";
import { onMounted, ref, toRefs, computed, watch } from "@vue/composition-api";
import helpers from "@/helpers";
import config from "@/config";
import axios from "axios";

let cancelToken;
const state = store.subLibraries.state;
const newSubLibraryForm = ref();
const noRecordsFound = computed(() => state.loadedAll && !state.data.length);

const memberFilter = (value) => {
  state.isMemberLoading = true;

  if (cancelToken) {
    cancelToken.cancel("Operation canceled due to new request.");
  }

  //Save the cancel token for the current request
  cancelToken = axios.CancelToken.source();
  const query =
    value && value.length ? `&searchTerm=` + encodeURIComponent(value) : "";
  axios
    .get(
      `${process.env.VUE_APP_API}/member?limit=40${query}&nameAndIdOnly=true`,
      {
        cancelToken: cancelToken.token
      }
    )
    .then((response) => {
      state.isMemberLoading = false;
      state.membersData = response.data.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

watch(
  () => state.memberSearchKey,
  (value) => {
    memberFilter(value);
  }
);

const loadSubLibraries = (showLoading = true) => {
  showLoading && (state.isLoading = true);
  axios
    .get(`${process.env.VUE_APP_API}/subLibraries`)
    .then((response) => {
      state.data = response.data;
      state.loadedAll = true;
    })
    .catch((error) => {
      return Promise.reject(error);
    })
    .finally(() => (state.isLoading = false));
};

export default () => {
  const scrollable = ref(null);
  const form = ref(null);

  const userExists = computed(() =>
    state.existingUsers.includes(state.formData.username)
  );

  const emailExists = computed(() =>
    state.existingEmails.includes(state.formData.email)
  );

  const required = (v) => !helpers.string.isEmpty(v) || "Required";

  const emailRules = [
    required,
    (v) => (helpers.string.validateEmail(v) ? true : "Enter a valid email"),
    () => (!emailExists.value ? true : "This email already exists")
  ];

  const usernameRules = [
    required,
    (un) => {
      if (!un || un.trim().length === 0) {
        return "Required";
      }
      if (!helpers.string.usernameCriteriaMet(un)) {
        return "Username must have atleast 5 characters and should only contain alphanumeric characters, underscore and dot";
      }
      return true;
    },
    (u) => (u.length <= 20 ? true : "Max: 20 characters"),
    () => (!userExists.value ? true : "This Username already exists")
  ];

  const nameRules = [
    required,
    (v) =>
      v.length >= 5 ? true : "Library name must be atleast 5 characters long"
  ];

  const passwordRules = [
    required,
    (v) =>
      helpers.string.passwordCriteriaMet(v)
        ? true
        : "Password must have uppercase, lowercase, digit, symbol and make sure the length is between 8 and 20"
  ];

  const confirmPasswordRules = [
    required,
    (v) =>
      state.formData.password === state.formData.confirmPassword
        ? true
        : "Password doesn't match"
  ];

  const register = () => {
    if (form.value.validate()) {
      state.loading = true;
      axios
        .post(
          `${process.env.VUE_APP_API_NOAUTH}/register?subLibrary=true`,
          state.formData
        )
        .then(() => {
          state.newSubLibraryDialog = false;
          store.app.showDialog(
            "Sub library creation successfull",
            `New Sub Library "${state.formData.name}" is successfully created`,
            "success"
          );
          state.resetFormData();
          form.value.resetValidation();
          loadSubLibraries(false);
        })
        .catch((error) => {
          if (error?.response?.data?.emailExists) {
            state.existingEmails.push(state.formData.email);
            form.value.validate();
          } else if (error?.response?.data?.usernameExists) {
            state.existingUsers.push(state.formData.username);
            form.value.validate();
          } else {
            store.app.showAlert(
              error?.response?.data?.message || "Some error occured!"
            );
          }
        })
        .finally(() => (state.loading = false));
    }
  };

  const filterMembers = (item, queryText) => {
    queryText = queryText.trim().toLocaleLowerCase();
    const name = item.name.trim().toLocaleLowerCase();
    const nameFound =
      (queryText.length === 1 && queryText == name[0]) ||
      (queryText.length > 1 && name.includes(queryText));
    const memberNumberFound =
      !isNaN(queryText) && queryText == item.memberNumber;
    return !queryText.length || nameFound || memberNumberFound;
  };

  const showAddSubLibraryPopup = () => {
    if (state.data?.length >= 5) {
      store.app.showDialog(
        "Maximum number of sub libraries reached",
        "You cannot add more than 5 sub libraries",
        "error"
      );
      return;
    }
    state.newSubLibraryDialog = true;
  };

  onMounted(() => {
    if (!state.isMemberLoading && !state.membersData.length) {
      memberFilter("");
    }
    if (!state.loadedAll) {
      loadSubLibraries();
    }
  });

  return {
    ...toRefs(state),
    showAddSubLibraryPopup,
    scrollable,
    filterMembers,
    newSubLibraryForm,
    noRecordsFound,
    nameRules,
    emailRules,
    passwordRules,
    usernameRules,
    confirmPasswordRules,
    required,
    districts: config.districts,
    userExists,
    emailExists,
    form,
    register
  };
};
