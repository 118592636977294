import store from "@/store";
import helpers from "@/helpers";
import axios from "axios";
import { toRefs, computed, ref, onMounted, watch } from "@vue/composition-api";

let cancelToken;
let cancelTokenMembers;
const state = store.logs.state;
const filters = store.logs.filters;
const isLargeLoading = computed(() => !state.loadedAll && !state.data.length);
const noRecordsFound = computed(() => state.loadedAll && !state.data.length);
const isEmpty = computed(() => !state.data.length);

const loadNextPage = (reset = false) => {
  if (reset) {
    state.resetData();
  }
  if ((!state.isLoading && !state.loadedAll) || reset) {
    state.isLoading = true;
    const alreadyLoadedCount = state.data.length;

    if (cancelToken) {
      cancelToken.cancel("Operation canceled due to new request.");
    }

    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source();
    const query = `?skip=${alreadyLoadedCount}&limit=${process.env.VUE_APP_ITEMS_PER_PAGE}${store.logs.filterQuery}`;
    axios
      .get(`${process.env.VUE_APP_API}/log${query}`, {
        cancelToken: cancelToken.token
      })
      .then((response) => {
        state.data.push(...response.data.data);
        state.loadedAll = !response.data.nextPage;
      })
      .finally(() => (state.isLoading = false))
      .catch((error) => {
        return Promise.reject(error);
      });
  }
};

const memberFilter = (value) => {
  filters.isMemberLoading = true;

  if (cancelTokenMembers) {
    cancelTokenMembers.cancel("Operation canceled due to new request.");
  }

  //Save the cancel token for the current request
  cancelTokenMembers = axios.CancelToken.source();
  const query =
    value && value.length ? `&searchTerm=` + encodeURIComponent(value) : "";
  axios
    .get(
      `${process.env.VUE_APP_API}/member?limit=40${query}&nameAndIdOnly=true`,
      {
        cancelTokenMembers: cancelTokenMembers.token
      }
    )
    .then((response) => {
      filters.isMemberLoading = false;
      filters.membersData = response.data.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

watch(
  () => filters.memberSearchKey,
  (value, oldValue) => {
    memberFilter(value);
  }
);

watch(
  () => store.logs.filterQuery,
  (value, oldValue) => {
    if (value && value != oldValue) {
      loadNextPage(true);
    }
  }
);

export default (props, { root }) => {
  const scrollable = ref(null);

  onMounted(() => {
    if (filters.isMemberLoading && !filters.membersData.length) {
      memberFilter("");
    }
    helpers.vueBarEvents(scrollable.value, {
      onScrollToBottom: () => {
        loadNextPage();
      },
      scrollToBottomOffset: 323
    });
    if (!store.logs.state.data.length) {
      loadNextPage();
    }
  });

  return {
    ...toRefs(store.logs.state),
    isLargeLoading,
    scrollable,
    isEmpty,
    noRecordsFound,
    onMounted
  };
};
