const languages = require("./languages");
const bookCategories = require("./bookCategories");

const bloodTypes = [
  "O+",
  "A+",
  "B+",
  "O-",
  "A-",
  "B-",
  "AB+",
  "AB-",
  "A1+",
  "A1-",
  "A1B+",
  "A1B-",
  "A2+",
  "A2-",
  "A2B+",
  "A2B-",
  "Bombay Blood Group",
  "INRA"
];

const scopes = [
  { id: "books", label: "Add/Edit Books" },
  { id: "distributions", label: "Distribute Books" },
  { id: "reports", label: "Download Reports" },
  { id: "qrcodes", label: "Generate Qr Codes as ZIP" }
];

const districts = [
  "Alappuzha",
  "Ernakulam",
  "Idukki",
  "Kannur",
  "Kasaragod",
  "Kollam",
  "Kottayam",
  "Kozhikode",
  "Malappuram",
  "Palakkad",
  "Pathanamthitta",
  "Thiruvananthapuram",
  "Thrissur",
  "Wayanad"
];

const paymentMethods = {
  card: "Card",
  debit: "Debit Card",
  credit: "Credit Card",
  netbanking: "NetBanking",
  upi: "UPI",
  emi: "EMI",
  cardless_emi: "Cardless EMI",
  bank_transfer: "Bank Transfer",
  emandate: "Emandate",
  paylater: "Pay Later",
  wallet: {
    icic: "ICICI Bank PayLater",
    freecharge: "FreeCharge",
    getsimpl: "GetSimpl",
    hdfc: "FlexiPay by HDFC Bank",
    lazypay: "LazyPay",
    payzapp: "PayZapp",
    airtelmoney: "Airtel Money",
    mobikwik: "MobiKwik",
    jiomoney: "JioMoney",
    olamoney: "Ola Money",
    phonepe: "PhonePe",
    phonepeswitch: "PhonePe Switch",
    paypal: "PayPal",
    amazonpay: "Amazon Pay"
  }
};

module.exports = {
  districts,
  bloodTypes,
  scopes,
  paymentMethods,
  languages,
  bookCategories
};
