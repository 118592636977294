<template>
  <div id="app">
    <AppMisc />
    <PublicPageLayout v-if="isPublicPage" />
    <MainLayout v-else-if="isLoggedIn" />
    <LoginLayout v-else />
  </div>
</template>
<script>
import MainLayout from "./layouts/MainLayout";
import LoginLayout from "./layouts/LoginLayout";
import PublicPageLayout from "./layouts/PublicPageLayout.vue";
import AppMisc from "./components/AppMisc";
import { useApp } from "./modules";
export default {
  components: {
    MainLayout,
    PublicPageLayout,
    LoginLayout,
    AppMisc
  },
  setup: useApp
};
</script>

<style lang="scss">
@import "./assets/css/ocean-theme.scss";
</style>
