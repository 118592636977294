import { reactive, computed } from "@vue/composition-api";

const state = reactive({
  loadedAll: false,
  isLoading: false,
  data: [],
  resetData: function () {
    this.data = [];
    this.loadedAll = false;
  }
});

const filterDefaultState = {
  searchKey: null,
  searchBy: null,
  stock: null,
  membershipPlan: null,
  orderBy: "memberNumber",
  dueType: null,
  genderType: null,
  showAdults: false,
};

const filters = reactive({
  ...filterDefaultState,
  showFilterOnXs: false,
  getSearchLabel: function () {
    switch (this.searchBy) {
      case 'memberNumber':
        return 'Member Id';
      case 'memberName':
        return 'Member Name';
      case 'bloodType':
        return 'Blood Type';
      default:
        return 'Search'
        break;
    }
  },
  toggleFilterOnXs: function () {
    this.showFilterOnXs = !this.showFilterOnXs;
  },
  resetFilter: function () {
    for (const key in filterDefaultState) {
      this[key] = filterDefaultState[key];
    }
  },
  searchByFilters: [
    { name: "Member Id", _id: "memberNumber" },
    { name: "Member Name", _id: "memberName" },
    { name: "Blood Type", _id: "bloodType" }
  ],
  searchByOrder: [
    { name: "Member id", orderBy: "memberNumber", orderType: 1 },
    { name: "Name", orderBy: "name", orderType: 1 },
    {
      name: "Recently joined",
      orderBy: "dateOfJoin",
      orderType: -1
    }
  ],
  filterByGender: [
    { name: "Male", genderType: "m" },
    { name: "Female", genderType: "f" },
    { name: "Other", genderType: "o" }
  ],
  filterByDues: [
    { name: "With dues", dueType: "withDues" },
    { name: "No dues", dueType: "noDues" }
  ],
  membershipPlanFilters: [],
  isMemberClassLoaded: false
});

const filterQuery = computed(() => {
  let filterQuery = "";
  filterQuery +=
    filters.searchKey && filters.searchKey.length
      ? `&searchTerm=${encodeURIComponent(filters.searchKey)}`
      : "";
  filterQuery +=
    filters.searchBy && filters.searchBy.length
      ? `&searchBy=${filters.searchBy}`
      : "";
  filterQuery += filters.stock ? `&stock=${filters.stock}` : "";
  filterQuery += filters.membershipPlan
    ? `&membershipPlan=${filters.membershipPlan}`
    : "";
  filterQuery += filters.dueType ? `&dueType=${filters.dueType}` : '';
  filterQuery += filters.showAdults ? `&showAdults=true` : '';
  filterQuery += filters.genderType ? `&gender=${filters.genderType}` : '';

  const selectedOrder = filters.searchByOrder.find(
    (order) => order.orderBy === filters.orderBy
  );

  filterQuery += `&orderType=${selectedOrder.orderType}`;
  filterQuery += `&orderBy=${selectedOrder.orderBy}`;

  return filterQuery;
});

export default reactive({
  state,
  filters,
  filterQuery
});
