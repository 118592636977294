import { reactive, computed } from "@vue/composition-api";
import axios from "axios";

const loadMemberClasses = () => {
  axios
    .get(`${process.env.VUE_APP_API}/member/membershipPlan`)
    .then((response) => {
      filters.membershipPlanFilters = response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    })
    .finally(() => (filters.isMemberClassLoaded = true));
};

const state = reactive({
  loadedAll: false,
  isLoading: false,
  data: [],
  resetData: function () {
    this.data = [];
    this.loadedAll = false;
  }
});

const filterDefaultState = {
  isPdfLoading: false,
  isXlsxLoading: false,
  membershipPlan: null,
  orderBy: "memberNumber",
  dueType: null,
  genderType: null,
  showAdults: false,
};

const filters = reactive({
  ...filterDefaultState,
  showFilterOnXs: false,
  toggleFilterOnXs: function () {
    this.showFilterOnXs = !this.showFilterOnXs;
  },
  resetFilter: function () {
    for (const key in filterDefaultState) {
      this[key] = filterDefaultState[key];
    }
  },
  searchByOrder: [
    { name: "Member id", orderBy: "memberNumber", orderType: 1 },
    { name: "Name", orderBy: "name", orderType: 1 },
    {
      name: "Recently joined",
      orderBy: "dateOfJoin",
      orderType: -1
    }
  ],
  filterByDues: [
    { name: "With dues", dueType: "withDues" },
    { name: "No dues", dueType: "noDues" }
  ],
  filterByGender: [
    { name: "Male", genderType: "m" },
    { name: "Female", genderType: "f" },
    { name: "Other", genderType: "o" }
  ],
  membershipPlanFilters: [],
  isMemberClassLoaded: false,
  loadMemberClasses
});

const filterQuery = computed(() => {
  let filterQuery = "";
  filterQuery += filters.membershipPlan
    ? `&membershipPlan=${filters.membershipPlan}`
    : "";
  filterQuery += filters.dueType ? `&dueType=${filters.dueType}` : '';

  const selectedOrder = filters.searchByOrder.find(
    (order) => order.orderBy === filters.orderBy
  );

  filterQuery += `&orderType=${selectedOrder.orderType}`;
  filterQuery += `&orderBy=${selectedOrder.orderBy}`;
  filterQuery += filters.showAdults ? `&showAdults=true` : '';
  filterQuery += filters.genderType ? `&gender=${filters.genderType}` : '';

  return filterQuery;
});

export default reactive({
  state,
  filters,
  filterQuery
});
