import { computed, onMounted, ref, toRefs } from "@vue/composition-api";
import store from "@/store";
import helpers from "@/helpers";
import config from "@/config";
import login from "./login";
import axios from "axios";
const state = store.register.state;

export default (_, { root }) => {
  const form = ref(null);

  const userExists = computed(() =>
    state.existingUsers.includes(state.formData.username)
  );

  const emailExists = computed(() =>
    state.existingEmails.includes(state.formData.email)
  );

  const completeStep1 = () => {
    if (form.value.validate()) {
      state.loading = true;
      axios
        .request({
          url: `${process.env.VUE_APP_API_NOAUTH}/emailAndUsernameExists`,
          method: "get",
          params: {
            email: state.formData.email,
            username: state.formData.username
          }
        })
        .then((response) => {
          if (response?.data?.emailExists) {
            state.existingEmails.push(state.formData.email);
          }
          if (response?.data?.usernameExists) {
            state.existingUsers.push(state.formData.username);
          }
          if (form.value.validate()) {
            state.step1Done = true;
            state.formData.password = state.formData.confirmPassword = "";
            form.value.resetValidation();
          }
        })
        .catch((error) => {
          store.app.showAlert("Some error occured!");
        })
        .finally(() => (state.loading = false));
    }
  };

  const submit = () => {
    if (!state.step1Done) {
      completeStep1();
    } else if (form.value.validate()) {
      register();
    }
  };

  const register = () => {
    state.loading = true;
    axios
      .post(`${process.env.VUE_APP_API_NOAUTH}/register`, state.formData)
      .then((response) => {
        store.app.showLoader("Logging you in...");
        login.attemptLogin(
          () => {
            store.app.logIn();
            store.app.hideLoader();
            helpers.localStorage.setItem("logged_in", true);
            root.$router.push("/account");
            store.app.showDialog(
              "Welcome! (Your account is under Free Trail)",
              "Your have successfully registered to Kerala Library, your account is under Free Trail plan, please upgrade to Premium Plan to explore all features.",
              "warning"
            );
          },
          () => {
            store.app.hideLoader();
            store.app.showAlert("Some error occured!");
          },
          {
            userName: state.formData.username,
            password: state.formData.password
          }
        );
      })
      .catch((error) => {
        if (error?.response?.data?.emailExists) {
          state.existingEmails.push(state.formData.email);
          state.step1Done = false;
          form.value.validate();
        } else if (error?.response?.data?.usernameExists) {
          state.existingUsers.push(state.formData.username);
          state.step1Done = false;
          form.value.validate();
        } else {
          store.app.showAlert("Some error occured!");
        }
      })
      .finally(() => (state.loading = false));
  };

  const required = (v) => !helpers.string.isEmpty(v) || "Required";
  const length = (min, max) => {
    return (v) => {
      if (v.length < min) {
        return `Minimum of ${min} characters required`;
      } else if (v.length > max) {
        return `Maximum of only ${max} characters allowed`;
      }
      return true;
    };
  };

  const emailRules = [
    required,
    (v) => (helpers.string.validateEmail(v) ? true : "Enter a valid email"),
    () => (!emailExists.value ? true : "This email already exists"),
    length(1, 200)
  ];

  const usernameRules = [
    required,
    (un) => {
      if (!un || un.trim().length === 0) {
        return "Required";
      }
      if (!helpers.string.usernameCriteriaMet(un)) {
        return "Username must have atleast 5 characters and should only contain alphanumeric characters, underscore and dot";
      }
      if (userExists.value) {
        return "This username already exists";
      }
      return true;
    },
    length(1, 20)
  ];

  const nameRules = [required, length(5, 75)];

  const passwordRules = [
    (v) =>
      !state.step1Done ? true : !helpers.string.isEmpty(v) ? true : "Required",
    (v) =>
      !state.step1Done
        ? true
        : helpers.string.passwordCriteriaMet(v)
        ? true
        : "Password must have uppercase, lowercase, digit, symbol and make sure the length is between 8 and 20"
  ];

  const confirmPasswordRules = [
    (v) =>
      !state.step1Done
        ? true
        : state.formData.password === state.formData.confirmPassword
        ? true
        : "Password doesn't match"
  ];

  const gotoLogin = () => {
    store.loginLayout.state.showLogin = true;
    root.$router.push("/");
  };

  return {
    ...toRefs(state),
    valid: true,
    required,
    length,
    nameRules,
    emailRules,
    passwordRules,
    confirmPasswordRules,
    usernameRules,
    form,
    completeStep1,
    submit,
    userExists,
    emailExists,
    districts: config.districts,
    gotoLogin
  };
};
