import store from "@/store";
import { onMounted, ref, toRefs, watch, computed } from "@vue/composition-api";
import { scopes } from "@/config";
import helpers from "@/helpers";
import axios from "axios";

const state = store.users.state;
const newUserForm = ref();
const noRecordsFound = computed(() => state.loadedAll && !state.data.length);

watch(
  () => state.detailsDialog,
  (value) => {
    if (value) {
      if (!state.editMode) {
        newUserForm?.value?.resetValidation();
        state.resetNewUser();
      }
    } else {
      state.editMode = false;
      state.editPassword = false;
    }
  }
);

const editUserModal = (user) => {
  const userToEdit = helpers.getObjectCopy(user);
  if (userToEdit.scopes?.includes("all")) {
    userToEdit.scopes = scopes.map((s) => s.id);
  } else {
    userToEdit.scopes = userToEdit.scopes.filter(
      (s) => s != "read" && s != "write"
    );
  }
  const newUser = {};
  newUser["username"] = userToEdit.username;
  newUser["mustReturnByMe"] = userToEdit.mustReturnByMe;
  newUser["returnRestricted"] = userToEdit.returnRestricted;
  newUser["name"] = userToEdit.name;
  newUser["scopes"] = userToEdit.scopes;
  newUser["id"] = userToEdit._id;
  state.newUser = newUser;
  state.editMode = true;
  state.detailsDialog = true;
};

const showAddUserPopup = () => {
  if (state.data?.length >= 10) {
    store.app.showDialog(
      "Maximum number of users reached",
      "You cannot add more than 10 users",
      "error"
    );
  } else {
    state.detailsDialog = true;
  }
};

const saveNewUser = () => {
  if (newUserForm.value.validate()) {
    const data = { ...state.newUser };
    store.app.showLoader(data.id ? "Saving Data..." : "Adding User...");
    if (data.scopes.length === scopes.length) {
      data.scopes = ["all"];
    }
    axios[data.id ? "put" : "post"](
      `${process.env.VUE_APP_API}/admin/users`,
      data
    )
      .then(() => {
        state.detailsDialog = false;
        store.app.showDialog(
          "User Added",
          `A new user "${data.name}" has been ${
            data.id ? "updated" : "added"
          } successfully.`
        );
        loadUsers(false);
      })
      .catch(({ response }) => {
        if (response?.data?.userExists) {
          state.existingIds.push(data.username);
          newUserForm.value.validate();
        }
      })
      .finally(() => {
        store.app.hideLoader();
      });
  }
};

const passwordValidation = (pwd) => {
  if (!pwd || pwd.trim().length === 0) {
    return "Required";
  }
  if (!helpers.string.passwordCriteriaMet(pwd)) {
    return "Password must have uppercase, lowercase, digit, symbol and make sure the length is between 8 and 20";
  }
  return true;
};

const unValidation = (un) => {
  if (!un || un.trim().length === 0) {
    return "Required";
  }
  if (!helpers.string.usernameCriteriaMet(un)) {
    return "Username must have atleast 5 characters and should only contain alphanumeric characters, underscore and dot";
  }
  if (state.existingIds.includes(un)) {
    return "This username already exists, please try another one";
  }
  return true;
};

const scopeChange = (value, id) => {
  if (value) {
    state.addScope(id);
  } else {
    state.removeScope(id);
  }
};

const loadUsers = (showLoading = true) => {
  showLoading && (state.isLoading = true);
  axios
    .get(`${process.env.VUE_APP_API}/admin/users`)
    .then((response) => {
      state.data = response.data;
      state.loadedAll = true;
    })
    .catch((error) => {
      return Promise.reject(error);
    })
    .finally(() => (state.isLoading = false));
};

export default () => {
  const scrollable = ref(null);
  onMounted(() => {
    if (!state.loadedAll) {
      loadUsers();
    }
  });
  return {
    ...toRefs(state),
    scrollable,
    newUserForm,
    noRecordsFound,
    showAddUserPopup,
    editUserModal,
    scopeChange,
    saveNewUser,
    passwordValidation,
    unValidation,
    scopes
  };
};
