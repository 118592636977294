import localStorage from "./localStorage";
import setupinterceptor from "./setupinterceptor";
import common from "./common";
import string from "./string";
import dropzoneAxiosOptions from "./dropzoneAxiosOptions";
import customVuetifyComponents from "./customVuetifyComponents";

export default {
  localStorage,
  string,
  setupinterceptor,
  dropzoneAxiosOptions,
  customVuetifyComponents,
  ...common
};
