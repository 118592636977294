import store from "@/store";
import axios from "axios";
import { toRefs, watch, computed } from "@vue/composition-api";

let cancelToken, loadNextPage;
const filters = store.returnBook.filters;
const state = store.returnBook.state;
const mlState = computed(() => store.mainLayout.state || {});

const resetAllData = () => {
  store.distribute.state.resetData();
  store.books.state.resetData();
  store.logs.state.resetData();
  state.resetSelectedBooks();
  filters.member = null;
};

const initiateReturn = (hideDialog) => {
  store.app.showLoader("Returning...");
  axios
    .post(`${process.env.VUE_APP_API}/distributions/return`, {
      books: state.selectedBooks,
      inDate: `${state.returnDateText} ${state.returnTime}`,
      fromSubLibrary: filters.fromSubLibrary
    })
    .then(() => {
      resetAllData();
      loadNextPage && loadNextPage(true);
      store.app.showAlert("Returned successfully.");
      if (hideDialog) {
        hideDialog();
      }
    })
    .catch((error) => {
      if (error.response.status === 400) {
        store.app.showAlert(error.response.data.message);
      } else {
        return Promise.reject(error);
      }
    })
    .finally(() => {
      store.app.hideLoader();
    });
};

const memberFilter = (value) => {
  filters.isMemberLoading = true;

  if (cancelToken) {
    cancelToken.cancel("Operation canceled due to new request.");
  }

  //Save the cancel token for the current request
  cancelToken = axios.CancelToken.source();
  const query =
    value && value.length ? `&searchTerm=` + encodeURIComponent(value) : "";
  axios
    .get(
      `${process.env.VUE_APP_API}/member?limit=40${query}&nameAndIdOnly=true`,
      {
        cancelToken: cancelToken.token
      }
    )
    .then((response) => {
      filters.isMemberLoading = false;
      filters.membersData = response.data.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

watch(
  () => filters.memberSearchKey,
  (value, oldValue) => {
    memberFilter(value);
  }
);

const filterMembers = (item, queryText) => {
  queryText = queryText.trim().toLocaleLowerCase();
  const name = item.name.trim().toLocaleLowerCase();
  const nameFound =
    (queryText.length === 1 && queryText == name[0]) ||
    (queryText.length > 1 && name.includes(queryText));
  const memberNumberFound = !isNaN(queryText) && queryText == item.memberNumber;
  return !queryText.length || nameFound || memberNumberFound;
};

export default (props) => {
  loadNextPage = props.loadNextPage;
  if (filters.isMemberLoading && !filters.membersData.length) {
    memberFilter("");
  }
  return {
    ...toRefs(filters),
    ...toRefs(state),
    mlState,
    distributedDateMenu: false,
    returnOnMenu: false,
    returnOnTimeMenu: false,
    filterMembers,
    initiateReturn,
    statusColors: { "1": "green", "2": "orange", "3": "grey" }
  };
};
