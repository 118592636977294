import "../installCompositionApi";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueAsyncManager from "vue-async-manager";
import SmoothScrollbar from "vue-smooth-scrollbar";
import VCurrencyField from "v-currency-field";
import { VTextField } from "vuetify/lib";
import store from "./store";
import axios from "axios";
import helpers from "./helpers";
import VueApexCharts from "vue-apexcharts";
import Vuebar from "vuebar";
import moment from "moment";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib";
import VueMask from "v-mask";
import "./assets/css/main.scss";
import "./assets/css/responsive.scss";
import "./assets/css/vuebar.scss";

if (process.env.NODE_ENV === "development") {
  helpers.setWindowValues({ $: { axios, helpers, store, moment, store } });
}

helpers.setupinterceptor();
Vue.config.productionTip = false;
Vue.use(VueAsyncManager);
Vue.use(SmoothScrollbar);
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
Vue.use(Vuebar);
Vue.use(VueMask, {
  placeholders: {
    P: /[0-9]|\+/
  }
});
Vue.component("v-text-field", VTextField);
Vue.use(VCurrencyField, {
  autoDecimalMode: false
});
Vue.use(VueTelInputVuetify, {
  vuetify
});

helpers.vueAddTimeFomats(Vue);
helpers.customVuetifyComponents.addComponents(Vue);

Vue.config.errorHandler = function(error) {
  if (error.stack.includes("SmoothScrollbar.vue")) {
    // ignore error from SmoothScrollbar.vue
    return;
  }
  if (process.env.NODE_ENV == "development") {
    throw error;
  }
};

new Vue({
  router,
  vuetify,
  render: (h) => h(App)
}).$mount("#app");
