import { computed, toRefs } from "@vue/composition-api";
import store from "@/store";
import axios from "axios";
import Moment from "moment";

const state = store.dashboard.state;

const loadData = () => {
  state.showGraph = false;
  axios
    .get(`${process.env.VUE_APP_API}/libraryDashboardData`)
    .then((response) => {
      state.bookCard = response.data.bookCard;
      state.dueCard = response.data.dueCard;
      state.notRetunedGraphCard = response.data.notRetunedGraphCard;
    })
    .catch((error) => {
      return Promise.reject(error);
    })
    .finally(() => {
      state.showGraph = true;
    });
  loadDistributionGraph();
};

const loadDistributionGraph = () => {
  state.showDistribution = false;
  axios
    .get(
      `${process.env.VUE_APP_API}/book/abstract?year=${
        state.distributionsYear
      }${
        state.distributionsYearType === "Day wise"
          ? `&month=${state.distributionsMonth}`
          : ""
      }${
        state.distributionType
          ? `&type=${state.distributionType.toLowerCase()}`
          : ""
      }`
    )
    .then((response) => {
      state.distributionsGraphCard = response.data.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    })
    .finally(() => {
      state.showDistribution = true;
    });
};

const distributionsSeries = computed(() => {
  return [
    {
      name: "Books Distributed",
      data: state.distributionsGraphCard
        ? state.distributionsGraphCard.map((data) => data.count)
        : []
    }
  ];
});

const distributionsMonthNames = computed(() => {
  return state.distributionsGraphCard
    ? state.distributionsGraphCard.map((data) =>
        state.distributionsYearType === "Day wise"
          ? data.day
          : Moment(data.month, "MMM").format("MMMM")
      )
    : [];
});

const distributionsMonthString = computed(() =>
  Moment(state.distributionsMonth, "M").format("MMMM")
);

const notRetunedSeries = computed(() => {
  return [
    {
      name: "Books Not Returned",
      data: state.notRetunedGraphCard
        ? state.notRetunedGraphCard.map((data) => data.value)
        : []
    }
  ];
});

const notRetunedMonthNames = computed(() => {
  return state.notRetunedGraphCard
    ? state.notRetunedGraphCard.map((data) =>
        Moment(data.monthString, "MMM").format("MMMM")
      )
    : [];
});

const years = [];
for (let i = parseInt(Moment(app.getTime).format("YYYY")); i >= 1990; i--) {
  years.push(i);
}

const months = [];
for (let i = 1; i <= 12; i++) {
  months.push({
    text: Moment(i, "M").format("MMMM"),
    value: i
  });
}

export default () => {
  loadData();
  return {
    ...toRefs(state),
    years,
    months,
    distributionsMonthString,
    loadDistributionGraph,
    distributionsSeries,
    distributionsMonthNames,
    notRetunedSeries,
    notRetunedMonthNames
  };
};
