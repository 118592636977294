import store from "@/store";
import helpers from "@/helpers";
import axios from "axios";
import moment from "moment";
import { toRefs, computed, ref, onMounted, watch } from "@vue/composition-api";

let cancelToken;
const state = store.memberBills.state;
const isLargeLoading = computed(() => !state.loadedAll && !state.data.length);
const noRecordsFound = computed(() => state.loadedAll && !state.data.length);
const isEmpty = computed(() => !state.data.length);

const loadNextPage = (reset = false) => {
  if (reset) {
    state.resetData();
  }
  if ((!state.isLoading && !state.loadedAll) || reset) {
    state.isLoading = true;
    const alreadyLoadedCount = state.data.length;

    if (cancelToken) {
      cancelToken.cancel("Operation canceled due to new request.");
    }

    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source();
    const query = `?skip=${alreadyLoadedCount}&limit=${process.env.VUE_APP_ITEMS_PER_PAGE}${store.memberBills.filterQuery}`;
    axios
      .get(`${process.env.VUE_APP_API}/memberBill${query}`, {
        cancelToken: cancelToken.token
      })
      .then((response) => {
        state.data.push(...response.data.data);
        state.loadedAll = !response.data.nextPage;
      })
      .finally(() => (state.isLoading = false))
      .catch((error) => {
        return Promise.reject(error);
      });
  }
};

watch(
  () => store.memberBills.filterQuery,
  (value, oldValue) => {
    if (value && value != oldValue) {
      loadNextPage(true);
    }
  }
);

export default (props, { root }) => {
  const scrollable = ref(null);

  onMounted(() => {
    helpers.vueBarEvents(scrollable.value, {
      onScrollToBottom: () => {
        loadNextPage();
      },
      scrollToBottomOffset: 323
    });
    if (!store.memberBills.state.data.length) {
      loadNextPage();
    }
  });

  return {
    ...toRefs(store.memberBills.state),
    isLargeLoading,
    scrollable,
    isEmpty,
    noRecordsFound,
    loadBill: store.app.loadBill,
    onMounted
  };
};
