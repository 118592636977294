import { reactive, computed, watch } from "@vue/composition-api";
import Moment from "moment";
import store from ".";

const state = reactive({
  loadedAll: false,
  isLoading: false,
  data: [],
  resetData: function() {
    this.data = [];
    this.loadedAll = false;
  },
  selectedBooks: [],
  selectedBooksDetails: [],
  returnDate: null,
  returnDateText: null,
  returnTime: null,
  returnTimeText: null,
  resetReturnOn: function() {
    this.returnDate = Moment(store.app.getTime).format("YYYY-MM-DD");
    this.returnTime = Moment(store.app.getTime).format("HH:mm");
  },
  resetSelectedBooks: function() {
    this.selectedBooksDetails = [];
    this.selectedBooks = [];
  }
});

const distributedFilterMaxDate = computed(() => {
  if (store.app.getTime) {
    return store.app.getTime.toISOString();
  } else {
    return new Date().toISOString();
  }
});

const selectedmember = computed(() => {
  return filters.membersData.find((member) => member._id == filters.member);
});

const selectedsubLibrary = computed(() => {
  return filters.subLibraries.find(
    (library) => library._id == filters.subLibrary
  );
});

const filterDefaultState = {
  member: null,
  distributionType: "General",
  subLibrary: null,
  fromSubLibrary: false,
  memberSearchKey: null,
  searchKey: null,
  searchBy: null,
  stock: null,
  type: null,
  distributedDates: [],
  distributedDateRangeText: null,
  orderBy: "lastDistributedDate"
};

const filters = reactive({
  ...filterDefaultState,
  showFilterOnXs: false,
  isMemberLoading: true,
  toggleFilterOnXs: function() {
    this.showFilterOnXs = !this.showFilterOnXs;
  },
  resetFilter: function() {
    for (const key in filterDefaultState) {
      this[key] = filterDefaultState[key];
    }
    state.resetSelectedBooks();
    state.resetReturnOn();
  },
  distributedFilterMaxDate,
  selectedmember,
  selectedsubLibrary,
  membersData: [],
  searchByTypeFilters: [],
  isBookTypesLoaded: false,
  subLibraries: [],
  isSubLibrariesLoaded: false,
  searchByFilters: [
    { name: "Book Id", _id: "bookNumber" },
    { name: "Book Name", _id: "bookName" },
    { name: "Author Name", _id: "authorName" }
  ],
  searchByStock: [
    { name: "In", _id: 1 },
    { name: "Out", _id: 2 },
    { name: "Removed", _id: 3 }
  ],
  searchByOrder: [
    { name: "Name", orderBy: "name", orderType: 1 },
    { name: "Book id", orderBy: "bookNumber", orderType: 1 },
    {
      name: "Recently distributed",
      orderBy: "lastDistributedDate",
      orderType: -1
    },
    { name: "Most distributed", orderBy: "distributedCount", orderType: -1 },
    { name: "Recently added", orderBy: "dateAdded", orderType: -1 }
  ]
});

watch(
  () => filters.distributedDates,
  (value) => {
    const newDates = value.slice();
    if (
      newDates.length === 2 &&
      Moment(newDates[0], "YYYY-MM-DD").toDate() >
        Moment(newDates[1], "YYYY-MM-DD").toDate()
    ) {
      newDates.reverse();
    }
    filters.distributedDateRangeText = newDates
      .map((date) => {
        return Moment(date, "YYYY-MM-DD").format("DD-MM-YYYY");
      })
      .join("~");
  }
);

watch(
  () => state.returnDate,
  (value) => {
    state.returnDateText = Moment(value, "YYYY-MM-DD").format("DD-MM-YYYY");
  }
);

watch(
  () => filters.fromSubLibrary,
  () => {
    state.resetSelectedBooks();
  }
);

watch(
  () => state.returnTime,
  (value) => {
    state.returnTimeText = Moment(value, "HH:mm").format("hh:mm A");
  }
);

watch(
  () => filters.distributedDateRangeText,
  (value) => {
    if (!value) {
      filters.distributedDates = [];
    }
  }
);

const filterQuery = computed(() => {
  let filterQuery = "";
  filterQuery +=
    filters.searchKey && filters.searchKey.length
      ? `&searchTerm=${encodeURIComponent(filters.searchKey)}`
      : "";
  filterQuery +=
    filters.searchBy && filters.searchBy.length
      ? `&searchBy=${filters.searchBy}`
      : "";
  filterQuery += filters.member ? `&member=${filters.member}` : "";
  filterQuery +=
    filters.subLibrary && filters.fromSubLibrary
      ? `&subLibrary=${filters.subLibrary}`
      : "";
  filterQuery += !filters.fromSubLibrary ? "&stock=2" : "";
  if (filters.distributionType && filters.distributionType !== "General") {
    filterQuery += `&lastDistributedType=${filters.distributionType.toLowerCase()}`;
  } else {
    filterQuery += filters.type ? `&type=${filters.type}` : "";
  }

  const selectedOrder = filters.searchByOrder.find(
    (order) => order.orderBy === filters.orderBy
  );

  filterQuery += `&orderType=${selectedOrder.orderType}`;
  filterQuery += `&orderBy=${selectedOrder.orderBy}`;

  if (filters.distributedDateRangeText) {
    const dateRange = filters.distributedDateRangeText.split("~");
    if (dateRange[0]) {
      filterQuery += `&lastDistributedDateFrom=${dateRange[0]}`;
    }
    if (dateRange[1]) {
      filterQuery += `&lastDistributedDateTo=${dateRange[1]}`;
    }
  }

  return filterQuery;
});

export default reactive({
  state,
  filters,
  filterQuery
});
