import store from "@/store";
import axios from "axios";
const state = store.loginLayout.state;

const attemptLogin = (onSuccess, onError, cred = {}) => {
  const userName = cred?.userName || state.userName;
  const password = cred?.password || state.password;
  const keepSignedIn = state.keepSignedIn;
  state.loading = true;
  axios
    .post(`${process.env.VUE_APP_API_NOAUTH}/accesstoken`, {
      username: userName,
      password: password,
      grant_type: "password",
      keep_signed_in: keepSignedIn,
      set_cookie: true
    })
    .then((response) => {
      onSuccess && onSuccess(response);
    })
    .catch(({ response }) => {
      onError && onError(response);
    })
    .finally(() => {
      state.loading = false;
    });
};

export default {
  attemptLogin
};
