import { reactive, computed, ref } from "@vue/composition-api";
import helpers from "@/helpers";
import axios from "axios";

const state = reactive({
  loggedIn: null,
  title: "",
  route: null,
  screenSize: null,
  time: new Date(),
  isLoaderShow: false,
  loaderMessage: "",
  isDialogShow: false,
  dialogHeader: "",
  dialogMessage: "",
  dialogType: "primary",
  isConfirmShow: false,
  confirmHeader: "",
  confirmMessage: "",
  confirmType: "primary",
  confirmOkText: "Ok",
  confirmInput: null,
  confirmInputValue: "",
  confirmFormValid: false,
  confirmCancelText: "Cancel",
  confirmCallback: () => {},
  confirmCancel: () => {},
  snackBarText: "",
  snackBar: false,
  contactUsDialog: false,
  showBillDialog: false,
  billDetails: null,
  contactUsIsRenew: true,
  pricing: null
});

const appData = computed(() => state);

const confirmForm = ref(null);
const dialogMessage = computed(() => state.dialogMessage);
const dialogHeader = computed(() => state.dialogHeader);
const dialogType = computed(() => state.dialogType);

const confirmMessage = computed(() => state.confirmMessage);
const confirmHeader = computed(() => state.confirmHeader);
const confirmType = computed(() => state.confirmType);
const confirmOkText = computed(() => state.confirmOkText);
const confirmCancelText = computed(() => state.confirmCancelText);
const confirmInput = computed(() => state.confirmInput);
const contactUsDialog = computed(() => state.contactUsDialog);
const contactUsIsRenew = computed(() => state.contactUsIsRenew);
const pricing = computed(() => state.pricing);

const setPricing = (p) => (state.pricing = p);

const showContactUs = (isRenew = false) => (
  (state.contactUsDialog = true), (state.contactUsIsRenew = isRenew)
);

const hideContactUs = () => (state.contactUsDialog = false);

const showDialog = (title, content, type = "primary") => {
  state.isDialogShow = true;
  state.dialogType = type;
  state.dialogHeader = title || "";
  state.dialogMessage = content || "";
};

const hideDialog = () => (state.isDialogShow = false);

const hideConfirm = () => (state.isConfirmShow = false);

const showConfirm = ({
  title,
  content,
  type = "primary",
  callback,
  cancel,
  input,
  cancelText = "Cancel",
  okText = "Ok"
}) => {
  state.isConfirmShow = true;
  state.confirmType = type;
  state.confirmCancelText = cancelText;
  state.confirmOkText = okText;
  state.confirmHeader = title || "";
  state.confirmMessage = content || "";
  const hasInput = input === "text" || input === "password";
  confirmForm?.value?.reset && confirmForm?.value?.reset();
  if (callback) {
    state.confirmCallback = () => {
      const result = { message: "Confirmed" };
      if (hasInput) {
        if (!confirmForm.value.validate()) {
          return;
        }
        result.input = state.confirmInputValue;
      }
      state.isConfirmShow = false;
      callback(result);
    };
  }
  if (cancel) {
    state.confirmCancel = () => {
      const result = { message: "Cancelled" };
      if (hasInput) {
        if (!confirmForm.value.validate()) {
          return;
        }
        result.input = state.confirmInputValue;
      }
      state.isConfirmShow = false;
      cancel(result);
    };
  }
  if (hasInput) {
    state.confirmInput = input;
  }
};

const isLoaderShown = computed(() => state.isLoaderShow);

const isBackPossible = computed(() => {
  return state.route && state.route.previous.name;
});
const loaderMessage = computed(() => state.loaderMessage);

const isSnackBarShown = computed({
  get: function() {
    return state.snackBar;
  },
  set: function(value) {
    state.snackBar = value;
  }
});

const snackBarText = computed(() => state.snackBarText);
const setSnackBar = (value) => {
  state.snackBar = value;
};
const showAlert = (message) => {
  if (state.isDialogShow) {
    return;
  }
  state.snackBar = true;
  state.snackBarText = message;
};

const getTime = computed(() => state.time);
const setTime = (time) => {
  state.time = time;
};

const getScreenSize = computed(() => state.screenSize);
const setScreenSize = (size) => {
  state.screenSize = size;
};

const getTitle = computed(() => state.title);
const setTitle = (title) => {
  state.title = title;
};

const isSmallScreen = computed(
  () => state.screenSize === "sm" || state.screenSize === "xs"
);

const getRoute = computed(() => state.route);
const setRoute = (route) => {
  state.route = route;
};

const isLoggedIn = computed(() => state.loggedIn);
const logOut = () => {
  helpers.localStorage.deleteItem("logged_in");
  window.location.href = "/";
};
const logIn = () => {
  state.loggedIn = true;
};

const showLoader = (message) => {
  state.isLoaderShow = true;
  state.loaderMessage = message;
};

const hideLoader = () => {
  state.isLoaderShow = false;
  state.loaderMessage = "";
};

const loadBill = (id) => {
  showLoader("Please wait...");
  axios
    .get(`${process.env.VUE_APP_API}/memberBill/${id}`)
    .then((response) => {
      state.billDetails = response.data;
      state.showBillDialog = true;
    })
    .finally(() => {
      hideLoader();
    });
};

export default reactive({
  get isDialogShow() {
    return state.isDialogShow;
  },
  set isDialogShow(value) {
    state.isDialogShow = value;
  },
  dialogHeader,
  dialogMessage,
  dialogType,
  showDialog,
  hideDialog,
  get isConfirmShow() {
    return state.isConfirmShow;
  },
  get confirmCallback() {
    return state.confirmCallback;
  },
  get confirmCancel() {
    return state.confirmCancel;
  },
  set isConfirmShow(value) {
    state.isConfirmShow = value;
  },
  get confirmInputValue() {
    return state.confirmInputValue;
  },
  set confirmInputValue(value) {
    state.confirmInputValue = value;
  },
  get confirmFormValid() {
    return state.confirmFormValid;
  },
  set confirmFormValid(value) {
    state.confirmFormValid = value;
  },
  confirmOkText,
  confirmForm,
  confirmCancelText,
  confirmHeader,
  confirmMessage,
  confirmType,
  confirmInput,
  showConfirm,
  hideConfirm,
  showLoader,
  hideLoader,
  isLoaderShown,
  loaderMessage,
  isLoggedIn,
  logOut,
  logIn,
  setRoute,
  getRoute,
  getScreenSize,
  setScreenSize,
  isSmallScreen,
  setTime,
  getTime,
  isSnackBarShown,
  setSnackBar,
  snackBarText,
  showAlert,
  appData,
  isBackPossible,
  getTitle,
  setTitle,
  contactUsDialog,
  hideContactUs,
  showContactUs,
  contactUsIsRenew,
  pricing,
  setPricing,
  get showBillDialog() {
    return state.showBillDialog;
  },
  set showBillDialog(value) {
    state.showBillDialog = value;
  },
  billDetails: computed(() => state.billDetails),
  loadBill
});
