import { reactive, computed } from "@vue/composition-api";
import moment from "moment";
import store from "@/store";

const state = reactive({
  libraryName: "",
  libraryEmail: "",
  libraryHasLogo: "",
  libraryLogoUrl: "",
  bUploadInProgress: null,
  libraryBasicDetailsLoaded: false,
  mini: false,
  dense: false,
  sidebarMenu: true,
  title: "",
  windowWidth: window.innerWidth,
  windowHeight: window.innerHeight,
  scannerInput: "",
  lastKeyPress: null,
  keyUpTimeout: null
});
const isTemporary = computed(() => {
  return state.windowWidth < 750;
});

const smallHeader = (value = true) => {
  state.dense = value;
};

const toggleDrawer = () => {
  if (state.windowWidth < 750) {
    state.mini = false;
    state.sidebarMenu = !state.sidebarMenu;
  } else {
    state.mini = !state.mini;
  }
};

const isFreeTrail = computed(() => state.isFreeTrail === true);

const isExpired = computed(() => {
  const expiry = new Date(state.librarySubscription.expiryDate);
  const daysToExpiry = moment(expiry).diff(store.app.getTime, "days");
  return daysToExpiry <= 0;
});

const isExpiringSoon = computed(() => {
  const expiry = new Date(state.librarySubscription.expiryDate);
  const daysToExpiry = moment(expiry).diff(store.app.getTime, "days");
  return daysToExpiry > 0 && daysToExpiry <= 10;
});

export default reactive({
  state,
  toggleDrawer,
  isTemporary,
  smallHeader,
  isFreeTrail,
  isExpired,
  isExpiringSoon
});
