import { reactive, computed, watch } from "@vue/composition-api";

const state = reactive({
  showWindow: false,
  showPlanWindow: false,
  showConfirmMembershipFee: false,
  planFeePaid: false,
  selectedYear: null,
  member: null,
  feeData: [],
  membershipPlans: [],
  membershipPlansLoading: false,
  membershipPlan: null,
  isLoading: false,
  deleteFeeDialog: false,
  payUptoFeeDialog: false,
  deleteFeeDate: false,
  payUptoFeeDate: false
});

export default reactive({
  state
});
