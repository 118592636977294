import mainLayout from "./mainLayout";
import app from "./app";
import loginLayout from "./loginLayout";
import books from "./books";
import booksReport from "./booksReport";
import logs from "./logs";
import logsReports from "./logsReports";
import abstractFilters from "./abstractFilters";
import members from "./members";
import membersReports from "./membersReports";
import memberFeeWindow from "./memberFeeWindow";
import distribute from "./distribute";
import returnBook from "./return";
import typeWiseAbstract from "./typeWiseAbstract";
import singleBook from "./singleBook";
import singleMember from "./singleMember";
import dashboard from "./dashboard";
import bulkUpload from "./bulkUpload";
import reports from "./reports";
import settings from "./settings";
import users from "./users";
import register from "./register";
import billing from "./billing";
import memberBills from "./memberBills";
import subLibraries from "./subLibraries";

export default {
  app,
  loginLayout,
  mainLayout,
  books,
  booksReport,
  logs,
  logsReports,
  abstractFilters,
  members,
  membersReports,
  distribute,
  returnBook,
  memberFeeWindow,
  singleBook,
  singleMember,
  dashboard,
  bulkUpload,
  typeWiseAbstract,
  reports,
  settings,
  users,
  register,
  billing,
  memberBills,
  subLibraries
};
