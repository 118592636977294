import store from "@/store";
import axios from "axios";
import Moment from "moment";
import { toRefs, computed, watch, reactive } from "@vue/composition-api";
import helpers from "@/helpers";

const state = store.memberFeeWindow.state;

const years = computed(() => {
  const years = [];
  const startYear = new Date(state.member.dateOfJoin).getFullYear();
  const endYear = 2050;

  for (let eachYear = startYear; eachYear <= endYear; eachYear++) {
    years.push(eachYear);
  }

  return years;
});

const feeDataComputed = computed(() => {
  const modifiedData = state.feeData;
  if (modifiedData.length) {
    const startDate = new Date(modifiedData[0].payedForMonth);
    modifiedData.unshift(...Array(startDate.getMonth()).fill(false));
  }
  return modifiedData;
});

const memberIdNdYear = computed(() => {
  return state.member ? state.member._id + "_" + state.selectedYear : "";
});

const deleteMonthFee = () => {
  alert();
};

const loadSelectedYearData = () => {
  if (!state.selectedYear) {
    return;
  }
  state.isLoading = true;
  axios
    .get(
      `${process.env.VUE_APP_API}/member/${state.member._id}/getYearlyFeeData?year=${state.selectedYear}`
    )
    .then((response) => {
      state.feeData = response.data;
      state.isLoading = false;
    });
};

const isPending = (year, month) => {
  const now = store.app.getTime;
  const dateOfJoin = new Date(state.member.dateOfJoin);
  return (
    (year < now.getFullYear() ||
      (year == now.getFullYear() && month < now.getMonth())) &&
    (year > dateOfJoin.getFullYear() ||
      (year == dateOfJoin.getFullYear() && month >= dateOfJoin.getMonth()))
  );
};

const isAdvance = (year, month) => {
  const now = store.app.getTime;
  return (
    year > now.getFullYear() ||
    (year == now.getFullYear() && month > now.getMonth())
  );
};

const isBeforeJoin = (year, month) => {
  const dateOfJoin = new Date(state.member.dateOfJoin);
  return (
    year < dateOfJoin.getFullYear() ||
    (year == dateOfJoin.getFullYear() && month < dateOfJoin.getMonth())
  );
};

const monthDiff = (d1, d2) => {
  var months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months;
};

const feeToSettleUptoMonth = (year, month) => {
  let startMonth = new Date(state.member.dateOfJoin);
  startMonth.setDate(1);
  startMonth.setMonth(startMonth.getMonth() - 1);
  if (state.member.duesPayedUpto) {
    startMonth = new Date(state.member.duesPayedUpto);
    startMonth.setDate(1);
  }
  const endMonth = new Date(year, month, 1, 0, 0, 0, 0);
  return monthDiff(startMonth, endMonth) * state.member.dues.monthlyAmount;
};

const updateMemberData = (memberNumber, hideLoader) => {
  axios
    .get(
      `${process.env.VUE_APP_API}/member?searchTerm=${memberNumber}&searchBy=memberNumber`
    )
    .then((response) => {
      if (hideLoader) {
        store.app.hideLoader();
      }
      const memberData = response.data.data[0];
      if (memberData) {
        state.member = memberData;
        const dataIndex = store.members.state.data.findIndex((member) => {
          return member.memberNumber === memberNumber;
        });
        const singleMemberId = store.singleMember.state.memberData?._id;
        if (singleMemberId === memberData._id) {
          store.singleMember.state.memberData = memberData;
        }
        if (store.members.state.data[dataIndex]) {
          store.members.state.data[dataIndex] = { ...memberData };
          const temp = store.members.state.data;
          store.members.state.data = [];
          store.members.state.data = temp;
          state.member = { ...memberData };
        }
        resetMemberBillsPage();
      }
    });
};

const showDeleteFeeDialog = (year, month) => {
  state.deleteFeeDialog = true;
  state.deleteFeeDate = new Date(year, month, 25, 0, 0, 0, 0);
};

const showPayUpToFeeDialog = (year, month) => {
  const now = store.app.getTime;
  if (
    year > now.getFullYear() ||
    (year === now.getFullYear() && month >= now.getMonth())
  ) {
    if (!state.member.isMemberShipPlanFeesPaid) {
      store.app.showAlert(
        "Advance fee payment is not allowesd if membership fee is not paid"
      );
      return;
    }
  }
  state.payUptoFeeDialog = true;
  state.payUptoFeeDate = new Date(year, month, 25, 0, 0, 0, 0);
};

const resetMemberBillsPage = () => {
  store.memberBills.state.loadedAll = false;
  store.memberBills.state.data = [];
};

const deleteFee = () => {
  const dateUpto = new Date(state.deleteFeeDate.getTime());
  const dateOfJoin = new Date(state.member.dateOfJoin);
  let removeFeeOnjoin = 0;
  if (
    dateOfJoin.getMonth() === dateUpto.getMonth() &&
    dateOfJoin.getFullYear() === dateUpto.getFullYear()
  ) {
    removeFeeOnjoin = 1;
  } else {
    dateUpto.setMonth(dateUpto.getMonth() - 1);
  }

  store.app.showLoader("Deleting...");
  axios
    .post(`${process.env.VUE_APP_API}/member/${state.member._id}/payDues`, {
      payedUpto: Moment(dateUpto).format("DD-MM-YYYY"),
      removeFeeOnjoin
    })
    .then(() => {
      updateMemberData(state.member.memberNumber);
      store.app.hideLoader();
      loadSelectedYearData();
      state.deleteFeeDialog = false;
      resetMemberBillsPage();
    });
};

const payUpToFee = () => {
  const dateUpto = new Date(state.payUptoFeeDate.getTime());
  store.app.showLoader("Please wait...");
  axios
    .post(`${process.env.VUE_APP_API}/member/${state.member._id}/payDues`, {
      payedUpto: Moment(dateUpto).format("DD-MM-YYYY")
    })
    .then((response) => {
      updateMemberData(state.member.memberNumber);
      store.app.hideLoader();
      loadSelectedYearData();
      state.payUptoFeeDialog = false;
      response.data?.billId && store.app.loadBill(response.data.billId);
    });
};

watch(
  () => state.member,
  (value, oldValue) => {
    state.selectedYear = 12;
    state.planFeePaid = state.member.isMemberShipPlanFeesPaid;
    state.membershipPlan = state.member.membershipPlan._id;

    state.selectedYear = state.member.duesPayedUpto
      ? new Date(state.member.duesPayedUpto).getFullYear()
      : new Date(state.member.dateOfJoin).getFullYear();
  }
);

watch(
  () => state.showWindow,
  (value) => {
    if (value) {
      updateMemberData(state.member.memberNumber);
    }
  }
);

watch(
  () => reactive({ memberIdNdYear }).memberIdNdYear,
  () => {
    loadSelectedYearData();
  }
);

const cancelMembershipFeeChange = () => {
  state.showConfirmMembershipFee = false;
  state.planFeePaid = state.member.isMemberShipPlanFeesPaid;
};

const confirmMembershipFeeChange = () => {
  state.showConfirmMembershipFee = false;
  isPaidChange(state.planFeePaid, true);
};

const isPaidChange = (value, confirm) => {
  if (state.planFeePaid != state.member.isMemberShipPlanFeesPaid && !confirm) {
    state.showConfirmMembershipFee = true;
    return;
  } else if (
    state.planFeePaid != state.member.isMemberShipPlanFeesPaid &&
    confirm
  ) {
    store.app.showLoader("Saving...");
    axios[value ? "post" : "delete"](
      `${process.env.VUE_APP_API}/member/${state.member._id}/membershipFeePayment`
    ).then((response) => {
      updateMemberData(state.member.memberNumber, true);
      response.data?.billId && store.app.loadBill(response.data.billId);
    });
  }
};

const membershipChange = () => {
  const undoChange = () =>
    setTimeout(
      () => (state.membershipPlan = state.member.membershipPlan._id),
      100
    );
  if (state.member.dues.dueAmount > 0) {
    undoChange();
    store.app.showDialog(
      "Action not possible!",
      "Member should settle the dues and must pay current membership fee in order to change the membership class.",
      "error"
    );
    return;
  } else if (state.membershipPlan != state.member.membershipPlan._id) {
    store.app.showLoader("Saving...");
    axios
      .put(
        `${process.env.VUE_APP_API}/member/${state.member._id}/membershipChange`,
        {
          membershipPlan: state.membershipPlan
        }
      )
      .then(() => {
        store.app.showAlert("Membership class changed.");
        updateMemberData(state.member.memberNumber, true);
      })
      .catch((error) => {
        undoChange();
        if (error?.response?.data?.message) {
          store.app.showAlert(error?.response?.data?.message);
        } else {
          store.app.showAlert("Some error occured!");
        }
      })
      .finally(() => store.app.hideLoader());
  }
};

const loadMemberClasses = () => {
  state.membershipPlansLoading = true;
  axios
    .get(`${process.env.VUE_APP_API}/member/membershipPlan`)
    .then((response) => {
      state.membershipPlans = response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    })
    .finally(() => (state.membershipPlansLoading = false));
};

export default (props, { root }) => {
  if (!state.membershipPlans.length) {
    loadMemberClasses();
  }
  return {
    ...toRefs(store.memberFeeWindow.state),
    years,
    feeDataComputed,
    isPending,
    isPaidChange,
    deleteMonthFee,
    showDeleteFeeDialog,
    showPayUpToFeeDialog,
    deleteFee,
    payUpToFee,
    isBeforeJoin,
    feeToSettleUptoMonth,
    isAdvance,
    membershipChange,
    cancelMembershipFeeChange,
    confirmMembershipFeeChange,
    monthName: helpers.monthName
  };
};
