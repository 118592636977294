import { reactive, toRefs, watch } from "@vue/composition-api";

const state = reactive({
  bookData: null
});

const resetValue = () => {
  state.bookData = {
    name: "",
    author: null,
    type: null,
    price: null,
    dateAdded: null,
    invoiceNumber: "",
    remarks: "",
    language: "",
    source: "",
    invoiceDate: null
  };
};

export default (props, { root }) => {
  resetValue();
  return {
    ...toRefs(state),
    isModalShown: props.isShown
  };
};
