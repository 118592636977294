<template>
  <v-app>
    <v-row class="d-flex align-center login-parent">
      <v-col
        xl="4"
        lg="4"
        md="5"
        sm="12"
        class="login-wrap px-8 text-center d-flex flex-column justify-center"
        :class="showLogin && 'show'"
      >
        <h1 class="mb-6 logo-wrap d-flex align-center justify-center">
          <img :src="require('@/assets/logo.svg')" />
          <span class="pt-3 gradient-text">Kerala Library</span>
        </h1>
        <v-card
          tile
          class="mx-auto pb-8 px-9 login-box"
          :width="width"
          outlined
          elevation="20"
          :disabled="loading"
          :loading="loading"
        >
          <v-form
            ref="form"
            class="pt-4"
            v-model="valid"
            lazy-validation
            @submit.prevent="validate"
          >
            <v-text-field
              ref="usernameInput"
              placeholder=" "
              v-model="userName"
              :rules="nameRules"
              label="Username"
              required
              :error="usernameError.length > 0"
              :error-messages="usernameError"
              @keydown="usernameError = ''"
              tabindex="1"
              class="my-5"
            ></v-text-field>

            <v-text-field
              ref="passwordInput"
              placeholder=" "
              v-model="password"
              :rules="passwordRules"
              label="Password"
              required
              :error="passwordError.length > 0"
              :error-messages="passwordError"
              @keydown="passwordError = ''"
              :append-icon="showEye ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showEye = !showEye"
              :type="showEye ? 'text' : 'password'"
              tabindex="2"
              class="my-5 mb-0"
            ></v-text-field>
            <v-col sm="12" class="pa-0">
              <v-checkbox
                class="keep-signed-in mt-0"
                v-model="keepSignedIn"
                label="Keep me signed in"
              ></v-checkbox>
            </v-col>
            <v-col sm="12" class="pa-0 text-left mb-2 pt-1 forgot-password">
              <router-link to="/forgot-password" class="py-2"
                >Forgot Password?</router-link
              >
            </v-col>
            <div class="d-flex justify-end">
              <v-btn
                tile
                small
                tabindex="4"
                @click="showLogin = false"
                class="cancel-btn"
              >
                Cancel
              </v-btn>
              <v-btn
                tile
                small
                color="primary"
                tabindex="4"
                @click="$router.push('/sign-up')"
                class="register-btn"
              >
                Sign Up
              </v-btn>
              <v-btn
                small
                type="submit"
                tile
                :disabled="!valid"
                color="success"
                class="ml-4"
                tabindex="3"
              >
                Login
              </v-btn>
            </div>
          </v-form>
        </v-card>
      </v-col>
      <v-col xl="8" lg="8" md="7" class="login-content d-md-block text-center">
        <div class="d-flex justify-center align-center flex-column">
          <v-carousel
            cycle
            :height="carouselHeight[scnSize] ? carouselHeight[scnSize] : 400"
            hide-delimiter-background
            hide-delimiters
            :next-icon="false"
            :prev-icon="false"
            show-arrows-on-hover
          >
            <v-carousel-item>
              <div class="d-flex justify-center align-center flex-column px-6">
                <h5 class="feature-note">
                  Manage you library members and books data easily
                </h5>
                <div class="image-wrap">
                  <img src="@/assets/login/carousel-1.png" />
                </div>
              </div>
            </v-carousel-item>
            <v-carousel-item>
              <div class="d-flex justify-center align-center flex-column px-6">
                <h5 class="feature-note">
                  Analyze you monthly distribution growth
                </h5>
                <div class="image-wrap">
                  <img src="@/assets/login/carousel-2.png" />
                </div>
              </div>
            </v-carousel-item>
            <v-carousel-item>
              <div class="d-flex justify-center align-center flex-column px-6">
                <h5 class="feature-note">
                  Get your library data reports as excel and pdf
                </h5>
                <div class="image-wrap">
                  <img src="@/assets/login/carousel-3.png" />
                </div>
              </div>
            </v-carousel-item>
          </v-carousel>
          <div class="search-warp d-flex">
            <v-combobox
              v-model="searchSelection"
              class="search"
              :filter="() => 'false'"
              @change="selectLibrary"
              @click="searchmenu = true"
              @blur="searchmenu = false"
              :items="searchResults"
              :loading="searching"
              :search-input.sync="typeSearchKey"
              item-text="name"
              item-value="_id"
              label="Search for books in libraries"
              :menu-props="{
                value:
                  !searching &&
                  typeSearchKey &&
                  typeSearchKey.length &&
                  searchmenu
              }"
            >
              <template
                v-slot:no-data
                v-if="typeSearchKey && typeSearchKey.length"
              >
                <v-list-item v-if="!searching">
                  <v-list-item-content>
                    <v-list-item-title>
                      No results matching "<strong>{{ typeSearchKey }}</strong
                      >"{{ searchDistrict && ` in ${searchDistrict}`
                      }}{{ searchLibrary && ` in ${searchLibrary.name}` }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template
                v-slot:prepend-item
                v-if="searchResults.length && !searchLibrary"
              >
                <p class="px-4 found-results">
                  Found results in {{ searchResults.length }}
                  {{ searchResults.length > 1 ? "libraries" : "library" }}
                  {{ searchDistrict && ` in ${searchDistrict}` }} (Select for
                  more details)
                </p>
              </template>
              <template v-slot:item="{ index, item }">
                <div v-if="!searchLibrary" class="found-results">
                  {{ item.name }}, {{ item.district }} (found
                  {{ item.booksCount }} books with keyword "{{
                    typeSearchKey
                  }}")
                </div>
                <div v-else class="found-results">
                  Found
                  {{ item.booksCount }} books with keyword "{{ typeSearchKey }}"
                  in {{ item.name }} (Select for more details)
                </div>
              </template>
            </v-combobox>
            <v-select
              v-model="searchIn"
              @blur="searchmenu = false"
              @change="() => ((searchDistrict = null), (searchLibrary = null))"
              class="search-in"
              label="Search in"
              clearable
              :items="['Library', 'District']"
            ></v-select>
            <v-select
              v-if="searchIn === 'District'"
              v-model="searchDistrict"
              @blur="searchmenu = false"
              class="search-in district"
              label="Select district"
              clearable
              :items="districts"
            ></v-select>
            <v-combobox
              v-if="searchIn === 'Library'"
              v-model="searchLibrary"
              @blur="searchmenu = false"
              class="search-in library"
              label="Select library"
              clearable
              :items="libraries"
              item-text="name"
              item-value="_id"
            ></v-combobox>
          </div>
        </div>
      </v-col>
      <div class="header">
        <a href="#" @click.prevent="showLogin = true">Login</a>
        |
        <router-link to="/sign-up">Sign Up</router-link>
        |
        <router-link to="/pricing">Pricing</router-link>
      </div>
      <div class="footer">
        <router-link to="/privacy">Privacy</router-link>
        |
        <router-link to="/terms">Terms</router-link>
        |
        <router-link to="/refund">Refund</router-link>
        |
        <router-link to="/contact">Contact Us</router-link>
        <span class="pricing-link">
          |<router-link to="/pricing">Pricing</router-link>
        </span>
      </div>
    </v-row>
    <v-dialog max-width="500" value="true" v-if="serchedLibrary != null">
      <template v-slot:default="dialog">
        <v-card>
          <v-card-text class="pt-6 text-center">
            Details of library <b>{{ serchedLibrary.name }}</b>
          </v-card-text>
          <v-card-text class="pb-0 library-details">
            <span>District:</span> <b>{{ serchedLibrary.district }}</b>
            <span>Address: </span><b>{{ serchedLibrary.address }}</b>
            <span>Phone: </span><b>{{ serchedLibrary.phone }}</b>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="serchedLibrary = null">Close</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </v-app>
</template>

<script>
import { useLoginLayout } from "@/modules";

export default {
  setup: useLoginLayout,
  data() {
    return {
      carouselHeight: {
        sm: 360,
        xs: 320
      }
    };
  }
};
</script>

<style lang="scss" scoped>
@function getGradient($angle, $color1: #0899ec, $color2: #15b998) {
  @return linear-gradient($angle, $color1, $color2);
}
.cancel-btn {
  display: none;
}
.header {
  z-index: 0;
  padding-right: 30px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(45deg, #0899ec, #15b998);
  z-index: 3;
  color: #fff;
  font-size: 13px;
  height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  display: none;
  a {
    color: #fff;
    padding: 0 5px;
  }
}
.login-parent {
  overflow: hidden;
  padding-bottom: 50px;
  &::after {
    pointer-events: none;
    content: "";
    position: fixed;
    left: 0;
    width: 20%;
    top: 0;
    bottom: 0;
    background-size: cover;
    background-position: left;
    height: 100vh;
    z-index: 6;
    background-image: url("~@/assets/backgrounds/layered-waves-haikei.svg");
  }
}
.forgot-password {
  margin-top: -9px;
  margin-bottom: 15px;
  font-size: 12px;
  a {
    color: #15b998;
  }
}
.logo-wrap {
  img {
    width: 100px;
  }
}
.login-wrap {
  height: 100%;
  position: relative;
  z-index: 7;
  .register-text {
    font-size: 14px;
    text-align: left;
    margin-top: 20px;
  }
  h1 {
    line-height: 0.95;
    text-align: left;
    span {
      font-size: 28px;
      padding-left: 20px;
    }
  }
}
.login-content {
  height: 100%;
  position: relative;
  &::before {
    content: "";
    background: getGradient(12deg);
    position: absolute;
    left: 0;
    right: -50%;
    top: -50%;
    bottom: -50%;
    z-index: 0;
    transform: rotate(4deg);
  }
  & > div {
    height: 100%;
    position: relative;
    z-index: 1;
    color: #fff;
    .feature-note {
      font-size: 50px;
    }
    .image-wrap {
      img {
        width: 250px;
        margin-top: 10px;
      }
    }
  }
  .search-warp {
    background-color: #fff;
    width: 80%;
    padding: 5px 40px;
    border-radius: 50px;
    max-width: 800px;
    column-gap: 20px;
    .search-in {
      width: 0;
    }
  }
}
.library-details {
  display: grid;
  grid-template-columns: 62px auto;
  row-gap: 4px;
  column-gap: 4px;
}
@media only screen and (max-width: 1200px) {
  .login-content {
    & > div {
      .feature-note {
        font-size: 40px;
      }
    }
  }
}
@media only screen and (max-width: 959px) {
  .login-content {
    &::before {
      position: fixed;
      transform: rotate(0deg);
    }
  }
  .pricing-link {
    display: none;
  }
  .cancel-btn {
    display: block;
  }
  .register-btn {
    display: none;
  }
  .header {
    display: flex;
  }
  .login-parent {
    .found-results {
      font-size: 10px;
      padding: 2px 9px;
    }
    &::after {
      width: 8%;
    }
    & > div .image-wrap img {
      width: 150px;
    }
    .search-warp {
      flex-direction: column;
      .search-in {
        width: 100%;
      }
    }
    ::v-deep .v-select:not(.search) {
      padding: 0;
    }
  }
  .login-box {
    padding: 0 25px 30px 25px !important;
  }
  .feature-note {
    font-size: 20px !important;
    padding: 0 30px;
    margin-top: 50px;
  }
  .login-wrap {
    position: fixed;
    transition: top 0.6s;
    left: 0;
    right: 0;
    height: 100% !important;
    top: -200%;
    height: auto;
    width: auto;
    padding: 0 !important;
    &.show {
      top: 0;
    }
    .logo-wrap {
      display: none !important;
    }
  }
  .login-wrap {
    h1 {
      span {
        padding: 0;
      }
    }
  }
}
</style>

<style>
html {
  overflow-y: auto !important;
}
</style>
