import { toRefs, computed } from "@vue/composition-api";
import axios from "axios";
import moment from "moment";
import helpers from "@/helpers";
import store from "@/store";

const state = store.bulkUpload.state;
const bUploadInProgress = computed(
  () => store.mainLayout.state.bUploadInProgress
);

const showUndoConfirm = (response, dataType) => {
  if (!response.count) {
    store.app.showDialog(
      "No bulk uploads",
      "No updated bulk uploaded data not found (Please note, bulk uploaded data which are updated later cannot be deleted)"
    );
    return;
  }
  const message = response?.compleatedAt
    ? `Are you to delete all these ${
        response.count
      } ${dataType} data uploaded in last bulk upload on ${moment(
        response?.compleatedAt
      ).format("DD-MM-YYYY [at] hh:mm A")}?`
    : `Are you to delete all these ${response.count} ${dataType} data uploaded in last one hour?`;
  store.app.showConfirm({
    okText: "Confirm",
    title: "Are you sure?",
    content: message,
    type: "warning",
    callback: (a) => {
      store.app.showLoader("Please wait...");
      const apiPath = `bulkUpload${helpers.string.pascalCase(dataType)}Data`;
      axios
        .delete(
          `${process.env.VUE_APP_API}/${apiPath}?type=${
            response?.compleatedAt ? "last_upload" : "last_hour"
          }`
        )
        .then(() => {
          store.app.showDialog(
            "Bulk uploaded data deleted",
            `All ${response.count} ${dataType} data uploaded are deleted successfully`,
            "success"
          );
        })
        .catch((error) => {
          store.app.showAlert("Some error occured!");
        })
        .finally(() => store.app.hideLoader());
    },
    cancel: () => {}
  });
};

const undoUpload = (type = "last_upload", dataType = "books") => {
  store.app.showLoader("Please wait...");
  const apiPath = `bulkUpload${helpers.string.pascalCase(dataType)}Data`;
  axios
    .get(`${process.env.VUE_APP_API}/${apiPath}?type=${type}`)
    .then((response) => {
      showUndoConfirm(response.data, dataType);
    })
    .catch((error) => {
      store.app.showAlert("Some error occured!");
    })
    .finally(() => store.app.hideLoader());
};

export default (props, { root }) => {
  if (bUploadInProgress.value !== null) {
    helpers.getLibraryData();
  }
  return {
    ...toRefs(state),
    undoUpload,
    xlsxBooksSampleFileUrl: `${process.env.VUE_APP_PUBLIC_BUCKET}/samples/books-data-bulk-upload-sample.xlsx`,
    xlsxMembersSampleFileUrl: `${process.env.VUE_APP_PUBLIC_BUCKET}/samples/members-data-bulk-upload-sample.xlsx`,
    bUploadInProgress,
    getLibraryData: helpers.getLibraryData,
    booksExportOptions: helpers.dropzoneAxiosOptions({
      url: `${process.env.VUE_APP_API}/bulkUploadBooksData`,
      maxFilesize: 5.72205,
      acceptedFiles:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      maxFiles: 1,
      uploadPercentage: (percentage) => {
        if (percentage >= 100) {
          store.app.showLoader("Processing file...");
          state.timeOut = setTimeout(() => {
            store.app.hideLoader();
            state.clearBookDataExport();
            helpers.getLibraryData();
            store.app.showDialog(
              "Notice!",
              "Processing excel file is taking too long, this may take upto maximum of 15 min, please check after few minutes",
              "warning"
            );
          }, 25000);
        }
      },
      onSuccess: (response) => {
        const dataInserted = response?.data?.dataInserted;
        state.clearBookDataExport();
        store.app.hideLoader();
        store.app.showDialog(
          "Success",
          `Books data was successfully added, total of ${dataInserted} books data are added`
        );
      },
      onInvalidFile: () => {
        store.app.showDialog(
          "Error!",
          "File must be a valid xlsx file with maximum size of 6 Mb",
          "error"
        );
      },
      onCatch: (error) => {
        if (!state.bookDataFile) {
          return;
        }
        clearTimeout(state.timeOut);
        store.app.hideLoader();
        helpers.getLibraryData();
        if (error?.response?.data?.message) {
          store.app.showDialog(
            "Error!",
            error?.response?.data?.message,
            "error"
          );
        } else {
          store.app.showDialog("Error!", "Some error occured", "error");
        }
        state.clearBookDataExport();
      },
      startExport: (exportFunction) => {
        state.startBookDataExport = () => {
          store.app.showLoader("Uploading file...");
          exportFunction();
        };
      },
      clearExport: (removeFile) => {
        state.clearBookDataExport = () => {
          removeFile(state.bookDataFile);
          state.clearBookDataExport = state.bookDataFile = state.startBookDataExport = null;
        };
      },
      getFile: (file) => {
        state.bookDataFile = file;
      },
      dictDefaultMessage:
        '<i class="v-icon mdi mdi-upload"></i>Upload xlsx file'
    }),
    membersExportOptions: helpers.dropzoneAxiosOptions({
      url: `${process.env.VUE_APP_API}/bulkUploadMembersData`,
      maxFilesize: 5.72205,
      acceptedFiles:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      maxFiles: 1,
      uploadPercentage: (percentage) => {
        if (percentage >= 100) {
          store.app.showLoader("Processing file...");
          state.timeOut = setTimeout(() => {
            store.app.hideLoader();
            state.clearMemberDataExport();
            helpers.getLibraryData();
            store.app.showDialog(
              "Notice!",
              "Processing excel file is taking too long, this may take upto maximum of 15 min, please check after few minutes",
              "warning"
            );
          }, 25000);
        }
      },
      onSuccess: (response) => {
        const dataInserted = response?.data?.dataInserted;
        state.clearMemberDataExport();
        store.app.hideLoader();
        store.app.showDialog(
          "Success",
          `Members data was successfully added, total of ${dataInserted} members data are added`
        );
      },
      onInvalidFile: () => {
        store.app.showDialog(
          "Error!",
          "File must be a valid xlsx file with maximum size of 6 Mb",
          "error"
        );
      },
      onCatch: (error) => {
        if (!state.memberDataFile) {
          return;
        }
        clearTimeout(state.timeOut);
        store.app.hideLoader();
        helpers.getLibraryData();
        if (error?.response?.data?.message) {
          store.app.showDialog(
            "Error!",
            error?.response?.data?.message,
            "error"
          );
        } else {
          store.app.showDialog("Error!", "Some error occured", "error");
        }
        state.clearMemberDataExport();
      },
      startExport: (exportFunction) => {
        state.startMemberDataExport = () => {
          store.app.showLoader("Uploading file...");
          exportFunction();
        };
      },
      clearExport: (removeFile) => {
        state.clearMemberDataExport = () => {
          removeFile(state.memberDataFile);
          state.clearMemberDataExport = state.memberDataFile = state.startMemberDataExport = null;
        };
      },
      getFile: (file) => {
        state.memberDataFile = file;
      },
      dictDefaultMessage:
        '<i class="v-icon mdi mdi-upload"></i>Upload xlsx file'
    })
  };
};
