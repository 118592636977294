import { reactive } from "@vue/composition-api";
import moment from "moment";
import app from "./app";

const currentYear = parseInt(moment(app.getTime).format("YYYY"));
export default reactive({
  year: currentYear,
  yearType: "financialYear",
  distributionType: null,
  isPdfLoading: false,
  isXlsxLoading: false,
  resetYear: function() {
    this.year = currentYear;
  }
});
