import { reactive } from "@vue/composition-api";
import { scopes } from "@/config";

const state = reactive({
  detailsDialog: false,
  isLoading: false,
  loadedAll: false,
  formValid: true,
  existingIds: [],
  editMode: false,
  editPassword: false,
  newUser: {
    username: "",
    name: "",
    password: "",
    mustReturnByMe: false,
    returnRestricted: false,
    scopes: scopes.map((s) => s.id)
  },
  data: [],
  resetData: function() {
    this.data = [];
    this.loadedAll = false;
  },
  resetNewUser: function() {
    delete this.newUser.id;
    this.newUser.username = this.newUser.name = this.newUser.password = "";
    this.newUser.scopes = scopes.map((s) => s.id);
  },
  addScope: function(scopeId) {
    this.newUser.scopes.push(scopeId);
  },
  removeScope: function(scopeId) {
    this.newUser.scopes = this.newUser.scopes.filter((s) => s != scopeId);
  }
});

export default reactive({ state });
