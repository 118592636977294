<template>
  <div>
    <v-dialog v-model="isLoaderShown" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          {{ loaderMessage }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar width="auto" top v-model="isSnackBarShown" tile :timeout="3000">
      {{ snackBarText }}
      <v-btn
        color="blue"
        style="float: right"
        text
        x-small
        @click="setSnackBar(false)"
      >
        Close
      </v-btn>
    </v-snackbar>
    <v-dialog v-model="isDialogShow" width="500">
      <v-card>
        <v-toolbar v-if="dialogHeader !== ''" :color="dialogType" dark>
          <span> {{ dialogHeader }}</span>
        </v-toolbar>

        <v-card-text class="pt-6"> {{ dialogMessage }} </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" tile text @click="hideDialog"> Ok </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isConfirmShow" width="500">
      <v-card>
        <v-toolbar v-if="confirmHeader !== ''" :color="confirmType" dark>
          <span> {{ confirmHeader }}</span>
        </v-toolbar>

        <v-card-text class="pt-6">
          {{ confirmMessage }}
          <v-form
            autocomplete="off"
            ref="confirmForm"
            v-model="confirmFormValid"
            lazy-validation
          >
            <v-text-field
              v-model="confirmInputValue"
              v-if="confirmInput"
              :type="confirmInput"
              name="confirmInput"
              id="confirmInput"
              :rules="[(v) => (v && v.trim().length ? true : 'Required')]"
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn tile :color="confirmType" text @click="confirmCancel">
            {{ confirmCancelText }}
          </v-btn>
          <v-btn tile :class="confirmType" text @click="confirmCallback">
            {{ confirmOkText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog max-width="500" value="true" v-model="contactUsDialog">
      <template v-slot:default="dialog">
        <v-card>
          <v-card-text class="pt-6 contact-details-title">
            Contact us to {{ contactUsIsRenew ? "renew" : "upgrade" }} your
            account
          </v-card-text>
          <v-card-text class="pb-0 contact-details">
            <div>
              Email:
            </div>
            <a href="mailto:keralalibrary@gmail.com">keralalibrary@gmail.com</a>
            <div>
              Phone:
            </div>
            <a href="tel:+919048811501">+91 9048811501</a>
            <div></div>
            <a href="tel:+919447051374">+91 9447051374</a>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="hideContactUs">Close</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-dialog v-model="showBillDialog" width="450">
      <v-card v-if="billDetails" id="bill-card">
        <v-toolbar dark class="mb-6 ignore-from-print">
          <span> Cash Bill</span>
        </v-toolbar>

        <div class="text-center d-none">
          <h2>{{ mlState.libraryName }}</h2>
          <h4 v-if="mlState.libraryRegNo">
            Reg No: {{ mlState.libraryRegNo }}
          </h4>
          <h4>{{ mlState.libraryAddress }}</h4>
          <h5>Phone: {{ mlState.libraryPhone }}</h5>
          <h3>Cash Bill</h3>
        </div>

        <v-card-text class="pb-1">
          Bill no:
          <b>{{ billDetails.billNumber }}</b>
        </v-card-text>

        <v-card-text class="pb-1">
          Date:
          <b>{{ billDetails.createdAt | formatDate }}</b>
        </v-card-text>
        <v-card-text class="pb-1">
          Member Name:
          <b>{{ billDetails.member.name }}</b>
        </v-card-text>
        <v-card-text class="pb-1">
          Member Id:
          <b>#{{ billDetails.member.memberNumber }}</b>
        </v-card-text>
        <v-card-text class="pb-1">
          Membership Class:
          <b>{{ billDetails.due.memberShipPlan }}</b>
        </v-card-text>

        <v-card-text v-if="billDetails.duesPayed" class="pb-1 fix">
          Amount payed: <b>₹{{ billDetails.duesPayed }}</b>
        </v-card-text>

        <v-card-text v-if="billDetails.type.startsWith('due')" class="pb-1">
          Monthly Fee Payment for
          <span v-if="isSameMonth(billDetails)">
            <b>{{ billDetails.fromDate | formatDateMonthAndYear }}</b>
          </span>
          <span v-else>
            <b>{{ billDetails.fromDate | formatDateMonthAndYear }}</b>
            to
            <b>{{ billDetails.toDate | formatDateMonthAndYear }}</b>
          </span>
          <span v-if="billDetails.type.endsWith('delete')" class="error--text">
            was deleted
          </span>
        </v-card-text>
        <v-card-text v-else class="pb-1">
          Membership Class fee payment
          <template v-if="billDetails.membershipPlanName">
            for the Membership Class
            <b>"{{ billDetails.membershipPlanName }}"</b>
          </template>
          <span v-if="billDetails.type.endsWith('delete')" class="error--text">
            was deleted
          </span>
        </v-card-text>

        <v-card-text
          v-if="
            billDetails.due.dueAmount > 0 &&
              billDetails.due.dueAmount === billDetails.due.memberShipFee
          "
          class="pb-1 warning--text"
        >
          This member has not payed membership fee of
          <b> ₹{{ billDetails.due.memberShipFee }}</b>
        </v-card-text>
        <v-card-text
          v-else-if="billDetails.due.dueAmount > 0"
          class="pb-1 warning--text"
        >
          This member has a pending due amount of
          <b> ₹{{ billDetails.due.dueAmount }} </b>
          <span v-if="!billDetails.member.isMemberShipPlanFeesPaid">
            (This also include membership fee of
            <b>₹{{ billDetails.due.memberShipFee }}</b
            >)
          </span>
        </v-card-text>
        <v-card-text v-else class="pb-1 success--text">
          This member has settled all dues.
        </v-card-text>

        <v-card-text
          class="pb-0 pt-4 smaller-text ignore-from-print"
          v-if="billDetails.createdBy"
        >
          This record was
          <span v-if="!billDetails.type.endsWith('delete')">made</span>
          <span v-else>deleted</span>
          by
          <b>{{ billDetails.createdBy.name }}</b>
        </v-card-text>

        <v-divider class="mt-4 ignore-from-print"></v-divider>

        <v-card-actions class="ignore-from-print">
          <v-spacer></v-spacer>
          <v-btn
            class="primary"
            tile
            text
            @click="
              printElement('bill-card', ` Bill no ${billDetails.billNumber}`)
            "
          >
            Print
          </v-btn>
          <v-btn class="success" tile text @click="showBillDialog = false">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { toRefs } from "@vue/composition-api";
import store from "@/store";
import moment from "moment";
import helpers from "@/helpers";

const mlState = store.mainLayout.state;
const isSameMonth = (bill) => {
  return (
    moment(bill.fromDate).format("MMM, YYYY") ===
    moment(bill.toDate).format("MMM, YYYY")
  );
};
export default {
  setup: () => ({
    ...toRefs(store.app),
    isSameMonth,
    mlState,
    printElement: helpers.printElement
  })
};
</script>

<style lang="scss" scoped>
.contact-details-title {
  font-size: 25px;
  text-align: center;
}

.contact-details {
  display: grid;
  grid-template-columns: 50px 1fr;
}
</style>
