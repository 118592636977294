module.exports = [
  "Aarogyam",
  "Aathmakadha",
  "Aattakkatha",
  "Abhimugam",
  "Acharam",
  "Agriculture",
  "Aithihyam",
  "Alankara Shastram",
  "Anthology",
  "Anubhavam",
  "Anweshanam",
  "Article",
  "Autobiography",
  "Award",
  "Balasahithyam",
  "Beauty",
  "Bhakthi",
  "Bhalitham",
  "Biography",
  "Business",
  "Cartoon",
  "Chalachithra Katha",
  "Charithram",
  "Cheru Kadha",
  "Chiithra Pusthakam",
  "Chinthakal",
  "Classics",
  "Collection Of Essays",
  "Collection Of Jokes",
  "Collection Of Poems",
  "Collection Of Stories",
  "Commentary",
  "Crime Novel",
  "Crime Thriller",
  "Criticism",
  "Dairy Kuripu",
  "Deshabhakthi Ganagal",
  "Detective Stories",
  "Devotional",
  "Detective Novel",
  "Dictionary",
  "Drama",
  "Dreams",
  "E-Reader",
  "Essay",
  "Education",
  "Ekangam",
  "Electronic",
  "English Composition",
  "Environmental Science",
  "Essays",
  "Festivals",
  "Forest Protection",
  "Gaanangal",
  "Gadhyam",
  "Ganitham",
  "General Knowledge",
  "Guide",
  "Hasya Kadhakal",
  "Hasya Nadakangal",
  "Hasya Novel",
  "Hasya Stories",
  "Hasyakavyam",
  "Hasyam",
  "Health",
  "Historical Study",
  "History",
  "Home",
  "Horror",
  "Indian Politics",
  "Interview",
  "Ithihasam",
  "Jeevacharitram",
  "Jyothisham",
  "Journalism",
  "Kadamkadha",
  "Kala",
  "Kala Vivarthanam",
  "Kalavivaranam",
  "Kavitha",
  "Kavitha Study",
  "Khantakavyam",
  "Kilipaaatt",
  "Krishi",
  "Kurippukal",
  "Lakhu Jeevacharithram",
  "Lekhanam",
  "Literary Criticism",
  "Literature",
  "Lyrics",
  "Magazine",
  "Magic",
  "Mahakavyam",
  "Malayala Vyakaranam",
  "Manasasthram",
  "Manthrikam",
  "Mappilappattu",
  "Masika",
  "Mathematics",
  "Memmories",
  "Nadakam",
  "Nadan Paattu",
  "Narmam",
  "Nattarivukal",
  "Niroopanam",
  "Niyamam",
  "Novel",
  "Novelets",
  "Orma",
  "Ottan Thullan",
  "Pachaka Kuripu",
  "Padyam",
  "Paristhithi",
  "Pattukal",
  "Pazhamchollu",
  "Periodicals",
  "Poem",
  "Poetry",
  "Prabandham",
  "Prabhashanam",
  "Pranayakuripp",
  "Preshnothari",
  "Purana Kadhkal",
  "Puranam",
  "Quiz",
  "Quran",
  "Reference",
  "Research",
  "Research Studies",
  "Saahithyam",
  "Sancharam",
  "Science",
  "Shasthram",
  "Social Science",
  "Songs",
  "Sports",
  "Stories",
  "Tamil Novel",
  "Thathwachintha",
  "Theatre ",
  "Travelling",
  "Thriller",
  "Upanyasam",
  "Vadakkan Paatukal",
  "Yathra Vivaranam"
];
