import { reactive } from "@vue/composition-api";
import moment from "moment";

const currentYear = parseInt(moment(app.getTime).format("YYYY"));
const currentMonth = parseInt(moment(app.getTime).format("MM"));

const state = reactive({
  showGraph: false,
  showDistribution: false,
  bookCard: null,
  dueCard: null,
  distributionsYearType: "Month wise",
  distributionsYear: currentYear,
  distributionsMonth: currentMonth,
  distributionType: null,
  distributionsGraphCard: null,
  notRetunedGraphCard: null
});

export default reactive({
  state
});
