import store from "@/store";
import helpers from "@/helpers";
import axios from "axios";
import {
  toRefs,
  computed,
  ref,
  onMounted,
  watch,
  onBeforeMount
} from "@vue/composition-api";

let cancelToken;
let router;
const state = store.members.state;
const filterState = store.members.filters;
const isLargeLoading = computed(() => !state.loadedAll && !state.data.length);
const noRecordsFound = computed(() => state.loadedAll && !state.data.length);
const isEmpty = computed(() => !state.data.length);

const loadMemberClasses = () => {
  axios
    .get(`${process.env.VUE_APP_API}/member/membershipPlan`)
    .then((response) => {
      filterState.membershipPlanFilters = response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    })
    .finally(() => (filterState.isMemberClassLoaded = true));
};

const loadNextPage = (reset = false) => {
  if (reset) {
    state.data = [];
    state.loadedAll = false;
  }
  if ((!state.isLoading && !state.loadedAll) || reset) {
    state.isLoading = true;
    const alreadyLoadedCount = state.data.length;

    if (cancelToken) {
      cancelToken.cancel("Operation canceled due to new request.");
    }

    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source();
    const query = `?skip=${alreadyLoadedCount}&limit=${process.env.VUE_APP_ITEMS_PER_PAGE}${store.members.filterQuery}`;
    axios
      .get(`${process.env.VUE_APP_API}/member${query}`, {
        cancelToken: cancelToken.token
      })
      .then((response) => {
        state.data.push(...response.data.data);
        state.loadedAll = !response.data.nextPage;
      })
      .finally(() => (state.isLoading = false))
      .catch((error) => {
        return Promise.reject(error);
      });
  }
};

const checkForQueries = () => {
  let query = router.history.current.query;
  if (query.withDues && query.withDues == "true") {
    filterState.dueType = "withDues";
    router.replace("/members");
  }
};

watch(
  () => store.members.filterQuery,
  (value, oldValue) => {
    if (value != oldValue) {
      loadNextPage(true);
    }
  }
);

export default (props, { root }) => {
  store.memberFeeWindow.state.showWindow = false;
  router = root._router;
  const scrollable = ref(null);

  onBeforeMount(() => {
    checkForQueries();
  });

  onMounted(() => {
    if (!filterState.isMemberClassLoaded) {
      loadMemberClasses();
    }
    helpers.vueBarEvents(scrollable.value, {
      onScrollToBottom: () => {
        loadNextPage();
      },
      scrollToBottomOffset: 290
    });
    if (!store.members.state.data.length) {
      loadNextPage();
    }
  });

  return {
    ...toRefs(store.members.state),
    isLargeLoading,
    scrollable,
    isEmpty,
    noRecordsFound,
    loadNextPage
  };
};
