import { reactive } from "@vue/composition-api";
import axios from "axios";
import moment from "moment";
import config from "@/config";
import store from "@/store";
import helpers from "@/helpers";

const state = reactive({
  data: [],
  loading: false,
  loadedOnce: false,
  refresh() {
    this.loading = true;
    axios
      .get(`${process.env.VUE_APP_API}/order`)
      .then(({ data }) => {
        this.data = formatData(data.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      })
      .finally(() => {
        this.loading = false;
        this.loadedOnce = true;
      });
  },
  download(_id, receiptNo) {
    store.app.showLoader("Downloading...");
    axios
      .get(`${process.env.VUE_APP_API}/order/${_id}?getAsPdf=true`)
      .then(({ data }) => {
        helpers.downloadFromDataUri(
          data.data,
          `Bill-Receipt-${receiptNo}.pdf`,
          "application/pdf"
        );
      })
      .catch((error) => {
        return Promise.reject(error);
      })
      .finally(() => {
        store.app.hideLoader();
      });
  }
});

const getPaymentMethod = (paymentDetails) => {
  if (paymentDetails?.wallet) {
    return (
      config.paymentMethods?.wallet?.[paymentDetails?.wallet] ||
      config.paymentMethods[paymentDetails?.method] ||
      paymentDetails?.method
    );
  }
  return (
    config.paymentMethods[paymentDetails?.method] || paymentDetails?.method
  );
};

const formatData = (data) => {
  return data.map((bill) => ({
    date: moment(bill.paymentCompletedDate).format("DD-MM-YYYY"),
    receiptNo: bill.receiptNo,
    desc: bill?.paymentDetails?.description,
    validity: `${moment(bill.subscribedDate).format("DD-MM-YYYY")} to ${moment(
      bill.expiryDate
    ).format("DD-MM-YYYY")}`,
    amount: `₹${bill.payedAmount}`,
    method: getPaymentMethod(bill?.paymentDetails),
    _id: bill._id
  }));
};

export default reactive({
  state
});
