import { reactive } from "@vue/composition-api";
import helpers from "@/helpers";

const keepSignedIn = helpers.localStorage.getItem("keep_signed_in")
  ? true
  : false;

const state = reactive({
  userName: "",
  password: "",
  searchSelection: null,
  searchIn: null,
  typeSearchKey: "",
  searchmenu: false,
  searchResults: [],
  libraries: [],
  searchDistrict: null,
  searchLibrary: null,
  searching: false,
  keepSignedIn,
  loading: false,
  showLogin: false,
  serchedLibrary: null
});

export default reactive({
  state
});
