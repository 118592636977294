import { reactive, computed, watch, ref } from "@vue/composition-api";
import store from "@/store";

const confirmForm = ref(null);
const planForm = ref(null);
const state = reactive({
  passwordFormValid: false,
  showPasswordDialog: false,
  currentPassword: "",
  newPassword: "",
  newPasswordConfirm: "",
  editName: false,
  newName: "",
  nameLoading: false,
  editEmail: false,
  newEmail: "",
  emailLoading: false,
  editAddress: false,
  newAddress: "",
  addressLoading: false,
  editRegNo: false,
  newRegNo: "",
  regNoLoading: false,
  editDistrict: false,
  newDistrict: "",
  districtLoading: false,
  editPin: false,
  newPin: "",
  pinLoading: false,
  editPhone: false,
  newPhone: "",
  phoneLoading: false,
  logoLoading: false,
  showLogoDialog: false,
  logoCropperSrc: "",
  confirmForm,
  subLibraryState: { sublibrary: true },
  membershipPlans: null,
  planForm,
  planFormValid: false,
  showMembershipPlan: false,
  editMembershipPlan: null,
  newMembershipPlan: { _id: 0, name: "", amount: "", monthlyAmount: "" }
});

export default state;
