<template>
  <v-app class="main-layout-parent">
    <v-sheet class="main-layout-sheet">
      <v-app-bar :dense="dense" app ref="siteHeader" outlined elevation="0">
        <v-app-bar-nav-icon @click.stop="toggleDrawer()"></v-app-bar-nav-icon>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu tile offset-y bottom :rounded="false">
          <template v-slot:activator="{ on, attrs }">
            <v-list-item-avatar v-bind="attrs" v-on="on">
              <v-img v-if="libraryHasLogo" :src="libraryLogoUrl"></v-img>
              <v-icon size="35" v-else>mdi-account-circle</v-icon>
            </v-list-item-avatar>
          </template>

          <v-list tile class="py-0 profile-menu">
            <v-list-item to="/account" link>
              <v-list-item-title><v-icon size="15" class="mr-2">mdi-account</v-icon>Account</v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout" link>
              <v-list-item-title><v-icon size="15" class="mr-2">mdi-logout</v-icon>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>
      <v-navigation-drawer v-model="sidebarMenu" app absolute :temporary="isTemporary" :permanent="sidebarMenu"
        :mini-variant.sync="mini">
        <smooth-scrollbar>
          <v-list :class="[mini ? 'd-none' : '']">
            <v-list-item class="px-4" v-if="!mini">
              <v-list-item-content>
                <v-list-item-title>Kerala Library</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="!mini">
              <v-list-item-content>
                <v-list-item-title class="title" style="min-height: 24px">
                  {{ libraryName }}
                </v-list-item-title>
                <v-list-item-subtitle style="min-height: 16px">
                  {{ libraryEmail }}
                </v-list-item-subtitle>
                <v-list-item-subtitle style="min-height: 16px; font-size: 12px">
                  Logged in as {{ user.name }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list class="pt-0">
            <v-list-item @click="hideSideBar" link to="/">
              <v-list-item-icon>
                <v-icon>mdi-view-dashboard</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Dashboard</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="hideSideBar" link to="/books">
              <v-list-item-icon>
                <v-icon>mdi-bookshelf</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Books</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="hideSideBar" link to="/distribute">
              <v-list-item-icon>
                <v-icon>mdi-call-split</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Distribute</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="hideSideBar" link to="/return">
              <v-list-item-icon>
                <v-icon>mdi-call-merge</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Return</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="hideSideBar" link to="/members">
              <v-list-item-icon>
                <v-icon>mdi-account-multiple-plus</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Members</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="hideSideBar" link to="/member-bills">
              <v-list-item-icon>
                <v-icon>mdi-receipt-text</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Member Bills</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="hideSideBar" link to="/logs">
              <v-list-item-icon>
                <v-icon>mdi-file</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Logs</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="hideSideBar" link to="/reports">
              <v-list-item-icon>
                <v-icon>mdi-post-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Reports</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="hideSideBar" link to="/bulkUpload">
              <v-list-item-icon>
                <v-icon>mdi-upload</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Bulk Upload</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="hideSideBar" link to="/users" v-if="user.role === 'admin' && !libraryParent">
              <v-list-item-icon>
                <v-icon>mdi-account-group</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Users</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="hideSideBar" link to="/sub-libraries" v-if="user.role === 'admin' && !libraryParent">
              <v-list-item-icon>
                <v-icon>mdi-file-tree</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Sub Libraries</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="hideSideBar" link to="/billing">
              <v-list-item-icon>
                <v-icon>mdi-format-align-left</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Billing</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </smooth-scrollbar>
      </v-navigation-drawer>
      <v-main>
        <v-row class="fill-height mx-0">
          <v-col class="px-0 py-0 my-0">
            <transition name="fade">
              <div ref="mainContentsParent" class="main-contents-parent">
                <Suspense :delay="200">
                  <MainPageSkeltonLoader slot="fallback" />
                  <router-view v-if="libraryBasicDetailsLoaded"></router-view>
                  <MainPageSkeltonLoader v-else />
                </Suspense>
              </div>
            </transition>
            <div class="footer">
              <router-link to="/privacy">Privacy</router-link>
              |
              <router-link to="/terms">Terms</router-link>
              |
              <router-link to="/refund">Refund</router-link>
              |
              <router-link to="/contact">Contact Us</router-link>
              |
              <router-link to="/pricing">Pricing</router-link>
            </div>
          </v-col>
        </v-row>
      </v-main>
    </v-sheet>
  </v-app>
</template>
<script>
import { useMainLayout } from "@/modules/";
import MainPageSkeltonLoader from "@/components/MainPageSkeltonLoader";
import store from "@/store";
const state = store.mainLayout.state;
export default {
  setup: useMainLayout,
  components: { MainPageSkeltonLoader },
  mounted() {
    const mainContentsParent = this.$refs.mainContentsParent;
    const siteHeader = this.$refs.siteHeader;
    const handleScannerInput = (routeName, input) => {
      store[routeName].filters.searchKey = input;
    };
    const functioncorrectSize = () => {
      mainContentsParent.style.height =
        window.innerHeight - siteHeader.$el.offsetHeight + "px";
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    };
    functioncorrectSize();
    window.onresize = functioncorrectSize;
    window.onkeyup = (e) => {
      if (
        !["books", "distribute", "returnBook"].includes(
          store.app.getRoute.current.name
        )
      ) {
        return;
      } else if (isNaN(e.key)) {
        state.scannerInput = "";
        return;
      }
      const lastPress = state.lastKeyPress;
      const now = new Date();
      state.lastKeyPress = now;
      if (lastPress) {
        const diff = now - lastPress;
        if (diff <= 30) {
          if (
            state.scannerInput === "v" ||
            (state.scannerInput === "V" && e.key === "Control")
          ) {
            if (document.activeElement.tagName !== "INPUT") {
              navigator.clipboard.readText().then((text) => {
                handleScannerInput(store.app.getRoute.current.name, text);
              });
            }
          }
          if (e.keyCode !== 13) {
            state.scannerInput += e.key;
            clearTimeout(state.keyUpTimeout);
            state.keyUpTimeout = setTimeout(() => {
              if (
                state.scannerInput.length &&
                !isNaN(state.scannerInput) &&
                document.activeElement.tagName !== "INPUT"
              ) {
                handleScannerInput(
                  store.app.getRoute.current.name,
                  state.scannerInput
                );
              }
              state.scannerInput = "";
            }, 40);
          }
        } else {
          state.scannerInput += e.key;
          clearTimeout(state.keyUpTimeout);
          state.keyUpTimeout = setTimeout(() => {
            state.scannerInput = "";
          }, 40);
        }
      } else {
        state.scannerInput += e.key;
        clearTimeout(state.keyUpTimeout);
        state.keyUpTimeout = setTimeout(() => {
          state.scannerInput = "";
        }, 40);
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.main-layout-parent {
  overflow: hidden;
}
</style>
