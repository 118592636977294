import { watch, toRefs, computed, onMounted, ref } from "@vue/composition-api";
import store from "@/store";
import axios from "axios";
import Moment from "moment";
import helpers from "@/helpers";
import { languages, bookCategories } from "@/config";

const state = store.singleBook.state;
let router = null;
const dateFields = [
  "dateAdded",
  "dateRemoved",
  "invoiceDate",
  "lastDistributedDate",
  "lastReturnedDate",
  "lastDistributedTime",
  "lastReturnedTime"
];

watch(
  () => state.bookRemoved,
  (value) => {
    if (value === 0) {
      state.editModeBookData.status = 1;
    } else {
      state.editModeBookData.status = 3;
    }
  }
);

watch(
  () => state.bookData,
  (newData, oldData) => {
    if (!oldData || newData.name != oldData.name) {
      changeTitle();
    }
  }
);

const changeTitle = () => {
  const currentTitle = store.app.getRoute.current.meta.title;
  store.app.setTitle(currentTitle + `: ${state.bookData.name}`);
};

const gotoNewBookPage = () => {
  router.push({ name: "new_book" });
};

const resetBookPages = () => {
  store.books.state.loadedAll = false;
  store.distribute.state.loadedAll = false;
  store.returnBook.state.loadedAll = false;
  store.books.state.data = [];
  store.distribute.state.data = [];
  store.returnBook.state.data = [];
};

const saveNewData = () => {
  if (!state.form.validate()) {
    store.app.showAlert("Please enter all required fields.");
    return;
  }
  const data = modifyDataForUpdate(state.editModeBookData);
  store.app.showLoader("Saving...");
  axios
    .post(`${process.env.VUE_APP_API}/book`, data)
    .then((response) => {
      store.app.showAlert("Book saved successfully.");
      resetBookPages();
      router.push({ name: "single_book", params: { id: response.data._id } });
    })
    .catch((error) => {
      return Promise.reject(error);
    })
    .finally(() => store.app.hideLoader());
};

const updateData = () => {
  if (!state.form.validate()) {
    store.app.showAlert("Please enter all required fields.");
    return;
  }

  const data = modifyDataForUpdate(state.editModeBookData);
  if (data.status === 2) {
    delete data.status;
  }
  store.app.showLoader("Updating...");
  axios
    .put(`${process.env.VUE_APP_API}/book/${state.bookData._id}`, data)
    .then(() => store.app.showAlert("Data updated successfully."))
    .finally(() => {
      store.app.hideLoader();
      state.bookData = {
        ...state.editModeBookData,
        author: helpers.string.pascalCase(state.editModeBookData.author.name),
        type: helpers.string.pascalCase(state.editModeBookData.type)
      };
      state.isEditMode = false;
      resetBookPages();
    })
    .catch((error) => {
      store.app.showAlert("Some error occured!");
      return Promise.reject(error);
    });
};

const modifyDataForUpdate = (data) => {
  let dataToUpdate = {};
  dataToUpdate.invoiceDate = data.invoiceDate
    ? Moment(data.invoiceDate, "YYYY-MM-DD").format("DD-MM-YYYY")
    : null;
  dataToUpdate.dateAdded = data.dateAdded
    ? Moment(data.dateAdded, "YYYY-MM-DD").format("DD-MM-YYYY")
    : null;
  dataToUpdate.dateRemoved =
    data.dateRemoved && data.status === 3
      ? Moment(data.dateRemoved, "YYYY-MM-DD").format("DD-MM-YYYY")
      : null;
  dataToUpdate.author = data.author.name;
  dataToUpdate.status = data.status;
  dataToUpdate.type = data.type;
  dataToUpdate.language = data.language || "";
  dataToUpdate.name = data.name;
  dataToUpdate.price = data.price;
  dataToUpdate.shelfNumber = data?.shelfNumber?.length ? data.shelfNumber : 0;
  dataToUpdate.source = data.source ? data.source : "";
  dataToUpdate.remarks = data.remarks ? data.remarks : "";
  dataToUpdate.isbn = data.isbn ? data.isbn : "";
  dataToUpdate.publisher = data.publisher ? data.publisher : "";
  dataToUpdate.catalogueNo = data.catalogueNo ? data.catalogueNo : "";
  dataToUpdate.stockNo = data.stockNo ? data.stockNo : "";
  dataToUpdate.invoiceNumber = data.invoiceNumber ? data.invoiceNumber : "";
  dataToUpdate.removedReason = data.removedReason ? data.removedReason : "";
  return dataToUpdate;
};

const formatBookData = (data, forEditData) => {
  let formattedData = { ...data };
  if (forEditData) {
    formattedData.author = { name: formattedData.author };
  }
  dateFields.forEach((field) => {
    if (formattedData[field]) {
      if (forEditData) {
        formattedData[field] = Moment(new Date(formattedData[field])).format(
          "YYYY-MM-DD"
        );
      } else {
        formattedData[field] = new Date(formattedData[field]);
      }
    }
  });
  return formattedData;
};

const loadNewBookData = () => {
  state.loading = false;
  state.newBook = true;
  state.bookData = state.editModeBookData = {
    bookNumber: "",
    invoiceDate: "",
    dateAdded: "",
    dateRemoved: "",
    author: "",
    status: 1,
    type: "",
    name: "",
    price: "",
    source: "",
    remarks: "",
    language: "",
    isbn: "",
    publisher: "",
    catalogueNo: "",
    stockNo: "",
    invoiceNumber: "",
    removedReason: ""
  };
};

const loadBookData = (id) => {
  state.loading = true;
  state.newBook = false;
  axios
    .get(`${process.env.VUE_APP_API}/book/${id}`)
    .then((response) => {
      state.bookData = formatBookData(response.data);
      state.editModeBookData = formatBookData(response.data, true);
      state.isEditMode = false;
      state.bookRemoved = state.editModeBookData.status === 3 ? 1 : 0;
      changeTitle();
    })
    .catch((error) => {
      if (error.response.status === 400 || error.response.status === 404) {
        router.push("/404");
      } else {
        return Promise.reject(error);
      }
    })
    .finally(() => {
      state.loading = false;
    });
};

const hasChanged = computed(() => {
  const data = state.bookData;
  const modifiedData = state.editModeBookData;

  if (
    Moment(data.invoiceDate, "YYYY-MM-DD").format("DD-MM-YYYY") !=
    Moment(modifiedData.invoiceDate, "YYYY-MM-DD").format("DD-MM-YYYY")
  ) {
    return true;
  }
  if (
    Moment(data.dateAdded, "YYYY-MM-DD").format("DD-MM-YYYY") !=
    Moment(modifiedData.dateAdded, "YYYY-MM-DD").format("DD-MM-YYYY")
  ) {
    return true;
  }
  if (
    Moment(data.dateRemoved, "YYYY-MM-DD").format("DD-MM-YYYY") !=
    Moment(modifiedData.dateRemoved, "YYYY-MM-DD").format("DD-MM-YYYY")
  ) {
    return true;
  }
  if (
    modifiedData.author &&
    helpers.string.pascalCase(data.author) !==
      helpers.string.pascalCase(modifiedData.author.name)
  ) {
    return true;
  }
  if (
    helpers.string.pascalCase(data.type) !==
    helpers.string.pascalCase(modifiedData.type)
  ) {
    return true;
  }
  if (data.language != modifiedData.language) {
    return true;
  }
  if (data.status !== modifiedData.status) {
    return true;
  }
  if (data.name !== modifiedData.name) {
    return true;
  }
  if (data.price != modifiedData.price) {
    return true;
  }
  if (data.source != modifiedData.source) {
    return true;
  }
  if (data.remarks != modifiedData.remarks) {
    return true;
  }
  if (data.isbn != modifiedData.isbn) {
    return true;
  }
  if (data.publisher != modifiedData.publisher) {
    return true;
  }
  if (data.catalogueNo != modifiedData.catalogueNo) {
    return true;
  }
  if (data.stockNo != modifiedData.stockNo) {
    return true;
  }
  if (data.invoiceNumber != modifiedData.invoiceNumber) {
    return true;
  }
  if (data.shelfNumber != modifiedData.shelfNumber) {
    return true;
  }
  if (data.removedReason != modifiedData.removedReason) {
    return true;
  }
  return false;
});

const isSubLibrary = computed(() =>
  store.mainLayout?.state?.libraryParent ? true : false
);

const goBackWard = () => {
  router.go(-1);
};

const showQrCodeModal = () => {
  if (store.mainLayout.isFreeTrail) {
    store.app.showDialog(
      "Not allowed",
      "This account is under free trail, upgrade your account to do this action.",
      "error"
    );
  } else if (store.mainLayout.isExpired) {
    store.app.showDialog(
      "Not allowed",
      `This account has been expired, ${
        store.mainLayout.isFreeTrail ? "upgrade" : "renew"
      } your account to do this action.`,
      "error"
    );
  } else {
    state.showQrCode = true;
    state.qrCodeBook = state.bookData._id;
  }
};

const loadNextNumber = () => {
  state.nextNumber = "";
  state.nextNumberLoading = true;
  axios
    .get(`${process.env.VUE_APP_API}/book/nextNumber`)
    .then((response) => {
      state.nextNumber = response.data.next;
    })
    .finally(() => {
      state.nextNumberLoading = false;
    });
};

export default (props, { root }) => {
  loadNextNumber();
  state.showQrCode = false;
  router = root._router;
  if (root._route.params.id) {
    loadBookData(root._route.params.id);
  } else {
    loadNewBookData();
  }

  onMounted(() => {
    state?.form?.resetValidation && state.form.resetValidation();
  });

  const categoryInput = ref(null);

  const toggleCustomCategory = () => {
    state.isCustomCategory = !state.isCustomCategory;
    state.editModeBookData.type = "";
    setTimeout(() => categoryInput.value.$el.querySelector("input").click(), 0);
  };

  return {
    statusColors: { "1": "green", "2": "orange", "3": "grey" },
    ...toRefs(state),
    isBackPossible: store.app.isBackPossible,
    updateData,
    categoryInput,
    languages,
    bookCategories,
    string: helpers.string,
    saveNewData,
    isSubLibrary,
    toggleCustomCategory,
    gotoNewBookPage,
    goBackWard,
    hasChanged,
    showQrCodeModal,
    formatDate: (date) => {
      if (!date) {
        return "";
      }
      return Moment(date, "YYYY-MM-DD").format("DD-MM-YYYY");
    }
  };
};
