import store from "@/store";
import axios from "axios";
import { toRefs, ref, watch, computed } from "@vue/composition-api";
import helpers from "@/helpers";

let cancelToken, loadNextPage;
const filters = store.distribute.filters;
const state = store.distribute.state;
const mlState = computed(() => store.mainLayout.state || {});
const searchInput = ref(null);
const resetAllData = () => {
  store.returnBook.state.resetData();
  store.books.state.resetData();
  store.logs.state.resetData();
  state.resetSelectedBooks();
  filters.member = null;
};

const completeDistribution = (hideDialog) => {
  store.app.showLoader("Distributing...");
  axios
    .post(`${process.env.VUE_APP_API}/distributions/distribute`, {
      books: state.selectedBooks,
      type: helpers.string.kebabCase(filters.distributionType),
      distributeTo: filters.toSubLibrary
        ? filters.selectedsubLibrary._id
        : filters.selectedmember._id,
      outDate: `${state.distributeDateText} ${state.distributeTime}`,
      toSubLibrary: filters.toSubLibrary
    })
    .then(() => {
      resetAllData();
      loadNextPage && loadNextPage(true);
      store.app.showAlert("Distributed successfully.");
      filters.searchKey = "";
      if (hideDialog) {
        hideDialog();
      }
    })
    .catch((error) => {
      if (error.response.status === 400) {
        store.app.showAlert(error.response.data.message);
      } else {
        return Promise.reject(error);
      }
    })
    .finally(() => {
      store.app.hideLoader();
    });
};

const initiateDistribute = (hideDialog) => {
  if (filters.toSubLibrary) {
    completeDistribution(hideDialog);
    return;
  }
  store.app.showLoader("Checking for dues...");
  axios
    .get(`${process.env.VUE_APP_API}/member/${filters.selectedmember._id}`)
    .then((result) => {
      let dues =
        result.data.dues.dueAmount > 0 ? result.data.dues.dueAmount : 0;
      if (!result.data.isMemberShipPlanFeesPaid && dues === 0) {
        dues += result.data.membershipPlan.amount;
      }
      if (dues <= 0) {
        completeDistribution(hideDialog);
      } else {
        store.app.showConfirm({
          title: "This member has dues",
          type: "warning",
          content: `This member has a due of ₹${dues}, are you sure to continue distributing?`,
          okText: "Ignore and continue",
          cancel: () => {
            hideDialog && hideDialog();
          },
          callback: (a) => {
            completeDistribution(hideDialog);
          }
        });
      }
    })
    .catch((error) => {
      return Promise.reject(error);
    })
    .finally(() => {
      store.app.hideLoader();
    });
};

const memberFilter = (value) => {
  filters.isMemberLoading = true;

  if (cancelToken) {
    cancelToken.cancel("Operation canceled due to new request.");
  }

  //Save the cancel token for the current request
  cancelToken = axios.CancelToken.source();
  let query =
    value && value.length ? `&searchTerm=` + encodeURIComponent(value) : "";
  if (!filters.toSubLibrary && filters.distributionType === "Balavedhi") {
    query += "&showAdults=false";
  }
  axios
    .get(
      `${process.env.VUE_APP_API}/member?limit=40${query}&nameAndIdOnly=true`,
      {
        cancelToken: cancelToken.token
      }
    )
    .then((response) => {
      filters.isMemberLoading = false;
      filters.membersData = response.data.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

watch(
  () => filters.memberSearchKey,
  (value) => {
    memberFilter(value);
  }
);

watch(
  () => filters.distributionType,
  (value, oldValue) => {
    if (
      (value === "Balavedhi" && oldValue !== "Balavedhi") ||
      (value !== "Balavedhi" && oldValue === "Balavedhi")
    ) {
      memberFilter("");
    }
  }
);

const filterMembers = (item, queryText) => {
  queryText = queryText.trim().toLocaleLowerCase();
  const name = item.name.trim().toLocaleLowerCase();
  const nameFound =
    (queryText.length === 1 && queryText == name[0]) ||
    (queryText.length > 1 && name.includes(queryText));
  const memberNumberFound = !isNaN(queryText) && queryText == item.memberNumber;
  return !queryText.length || nameFound || memberNumberFound;
};

export default (props) => {
  loadNextPage = props.loadNextPage;
  if (filters.isMemberLoading && !filters.membersData.length) {
    memberFilter("");
  }
  return {
    ...toRefs(filters),
    ...toRefs(state),
    resetDistributeTime: () => state.resetDistributeOn(),
    filterMembers,
    mlState,
    searchInput,
    purchaseDateMenu: false,
    distributeOnMenu: false,
    distributeOnTimeMenu: false,
    initiateDistribute,
    statusColors: { "1": "green", "2": "orange", "3": "grey" }
  };
};
