<template>
  <v-combobox
    :value="value"
    @input="(v) => $emit('input', v)"
    :search-input.sync="typeSearchKey"
    :items="data"
    :loading="loading"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <!-- Pass on all named slots -->
    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"></slot>
    <!-- Pass on all scoped slots -->
    <template
      v-for="slot in Object.keys($scopedSlots)"
      :slot="slot"
      slot-scope="scope"
    >
      <slot :name="slot" v-bind="scope"></slot>
    </template>
  </v-combobox>
</template>

<script>
import axios from "axios";
let cancelToken;
export default {
  props: { value: undefined, api: String, emitOnSearch: Boolean },
  inheritAttrs: false,
  data: () => ({
    initialLoad: false,
    typeSearchKey: "",
    data: [],
    loading: false
  }),
  methods: {
    loadData(searchTerm = "", isInitialLoad = false) {
      if (!this.api) {
        return;
      }
      if (!isInitialLoad && !this.initialLoad) {
        return;
      }
      searchTerm === null && (searchTerm = "");
      this.loading = true;
      let query = `?limit=40`;
      if (searchTerm !== "") {
        query += `&searchTerm=${encodeURIComponent(searchTerm)}`;
      }
      if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      cancelToken = axios.CancelToken.source();
      axios
        .get(`${process.env.VUE_APP_API}${this.api}${query}`, {
          cancelToken: cancelToken.token
        })
        .then((result) => {
          if (Array.isArray(result.data)) {
            this.data = result.data;
          } else {
            this.data = result.data.data;
          }
        })
        .finally(() => {
          this.initialLoad = true;
          this.loading = false;
        });
    }
  },
  mounted() {
    this.loadData("", true);
  },
  watch: {
    typeSearchKey: function(text) {
      if (this.emitOnSearch && this.$attrs["item-text"]) {
        const emitValue = {};
        emitValue[this.$attrs["item-text"]] = text;
        this.$emit("input", emitValue);
      }
      this.loadData(text);
    }
  }
};
</script>
