import store from "@/store";
import helpers from "@/helpers";
import config from "@/config";

export default (to, from) => {
  store.app.setRoute({
    previous: from,
    current: to
  });

  if (to.meta.bigHeader) {
    store.mainLayout.smallHeader(false);
  } else {
    store.mainLayout.smallHeader();
  }
  const isLoggedIn =
    store.app.isLoggedIn === null
      ? helpers.localStorage.getItem("logged_in")
      : store.app.isLoggedIn;
  const isPublicPage = to.meta?.public === true;
  document.title =
    isLoggedIn || isPublicPage ? to.meta.title : "Kerala Library";
  store.app.setTitle(to.meta.title);
  store.mainLayout.state.title = to.meta.title;
};
