import { reactive } from "@vue/composition-api";

const state = reactive({
  timeOut: null,
  startBookDataExport: null,
  clearBookDataExport: null,
  bookDataFile: null,
  startMemberDataExport: null,
  clearMemberDataExport: null,
  memberDataFile: null,
});


export default reactive({
  state
})