import { reactive, computed, watch } from "@vue/composition-api";
import Moment from "moment";
import axios from "axios";
import store from ".";

const purchaseFilterMaxDate = computed(() => {
  if (store.app.getTime) {
    return store.app.getTime.toISOString();
  } else {
    return new Date().toISOString();
  }
});

const loadBookTypes = () => {
  axios
    .get(`${process.env.VUE_APP_API}/book/type`)
    .then((response) => {
      filters.searchByTypeFilters = response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    })
    .finally(() => (filters.isBookTypesLoaded = true));
};

const filterDefaultState = {
  stock: null,
  isPdfLoading: false,
  isXlsxLoading: false,
  type: null,
  purchaseDates: [],
  purchaseDateRangeText: null,
  orderBy: "name"
};

const filters = reactive({
  ...filterDefaultState,
  resetFilter: function () {
    for (const key in filterDefaultState) {
      this[key] = filterDefaultState[key];
    }
  },
  purchaseFilterMaxDate,
  searchByStock: [
    { name: "In", _id: 1 },
    { name: "Out", _id: 2 },
    { name: "Removed", _id: 3 }
  ],
  searchByOrder: [
    { name: "Name", orderBy: "name", orderType: 1 },
    { name: "Book id", orderBy: "bookNumber", orderType: 1 },
    {
      name: "Recently distributed",
      orderBy: "lastDistributedDate",
      orderType: -1
    },
    { name: "Most distributed", orderBy: "distributedCount", orderType: -1 },
    { name: "Recently added", orderBy: "dateAdded", orderType: -1 }
  ],
  searchByTypeFilters: [],
  isBookTypesLoaded: false,
  loadBookTypes
});

watch(
  () => filters.purchaseDates,
  (value, oldValue) => {
    const newDates = value.slice();
    if (
      newDates.length === 2 &&
      Moment(newDates[0], "YYYY-MM-DD").toDate() >
      Moment(newDates[1], "YYYY-MM-DD").toDate()
    ) {
      newDates.reverse();
    }
    filters.purchaseDateRangeText = newDates
      .map((date) => {
        return Moment(date, "YYYY-MM-DD").format("DD-MM-YYYY");
      })
      .join("~");
  }
);

watch(
  () => filters.purchaseDateRangeText,
  (value, oldValue) => {
    if (!value) {
      filters.purchaseDates = [];
    }
  }
);

const filterQuery = computed(() => {
  let filterQuery = "";
  filterQuery += filters.stock ? `&stock=${filters.stock}` : "";
  filterQuery += filters.type ? `&type=${filters.type}` : "";

  const selectedOrder = filters.searchByOrder.find(
    (order) => order.orderBy === filters.orderBy
  );

  filterQuery += `&orderType=${selectedOrder.orderType}`;
  filterQuery += `&orderBy=${selectedOrder.orderBy}`;

  if (filters.purchaseDateRangeText) {
    const dateRange = filters.purchaseDateRangeText.split("~");
    if (dateRange[0]) {
      filterQuery += `&dateAddedFrom=${dateRange[0]}`;
    }
    if (dateRange[1]) {
      filterQuery += `&dateAddedTo=${dateRange[1]}`;
    }
  }

  return filterQuery;
});

export default reactive({
  filters,
  filterQuery
});
