import { ref, reactive } from "@vue/composition-api";

const state = reactive({
  memberData: null,
  editModeMemberData: null,
  showPhotoWindow: false,
  isEditMode: false,
  loading: false,
  dateOfJoinMenu: false,
  dateOfBirthMenu: false,
  dateRemovedMenu: false,
  newMember: false,
  nextNumberLoading: true,
  nextNumber: '',
  formValid: true,
  membershipPlans: [],
  membershipPlansLoading: false,
  membershipPlan: null,
  signPasscodeDialog: false,
  signPasscodeField: '',
  form: ref(null)
});

export default reactive({
  state
});
