import axios from "axios";
import store from "@/store";

const handleDroppedFile = (
  base64String,
  file,
  { url, onSuccess, onCatch, startExport, uploadPercentage, timeout }
) => {
  const axiosSend = () => {
    axios
      .post(
        url,
        { file: base64String },
        {
          onUploadProgress: (progressEvent) => {
            const percentage =
              (progressEvent.loaded / progressEvent.total) * 100;
            uploadPercentage && uploadPercentage(percentage);
            file.previewElement.querySelector(
              ".dz-upload"
            ).style.width = `${parseInt(percentage)}%`;
          },
          timeout: timeout || 0
        }
      )
      .then((response) => {
        onSuccess && onSuccess(response);
      })
      .catch((error) => {
        onCatch && onCatch(error);
      });
  };
  if (startExport) {
    startExport(axiosSend);
  } else {
    axiosSend();
  }
};

export default (options) => {
  options["error"] = function(file, message) {
    if (options.onInvalidFile) {
      options.onInvalidFile(file, message);
    } else {
      store.app.showAlert(message);
    }
    this.removeFile(file);
  };
  options["accept"] = function(file) {
    options.getFile && options.getFile(file);
    if (options.clearExport) {
      options.clearExport((fileToRemove) => {
        this.removeFile(fileToRemove);
      });
    }
    var reader = new FileReader();
    reader.onload = function(event) {
      var base64String = event.target.result;
      handleDroppedFile(base64String, file, options);
    };
    reader.readAsDataURL(file);
  };

  return options;
};
