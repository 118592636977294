import store from "@/store";
import axios from "axios";
import { toRefs, onMounted } from "@vue/composition-api";
import helpers from "@/helpers";

const state = store.mainLayout.state;
state.user = state.user || {};
state.libraryParent = state.libraryParent || false;

const hideSideBar = () => {
  if (window.innerWidth <= 748) {
    state.sidebarMenu = false;
  }
};

export default (props, { root }) => {
  onMounted(() => {
    // Side bar not closing bug fix
    document
      .querySelector(".main-layout-sheet")
      .addEventListener("click", (e) => {
        if (
          e.target ===
          document.querySelector(
            ".main-layout-sheet>.v-overlay>.v-overlay__scrim"
          )
        ) {
          state.sidebarMenu = false;
        }
      });
  });
  if (store.app.getScreenSize === "sm" || store.app.getScreenSize == "xs") {
    store.mainLayout.toggleDrawer();
  }
  const logout = () => {
    store.app.showLoader("Logging out...");
    axios
      .get(`${process.env.VUE_APP_API}/logout`)
      .then(() => {
        store.app.logOut();
      })
      .catch(() => {
        store.app.showAlert("Some error occured!");
      });
  };
  helpers.getLibraryData();
  return {
    ...toRefs(state),
    hideSideBar,
    logout,
    toggleDrawer: store.mainLayout.toggleDrawer,
    isTemporary: store.mainLayout.isTemporary
  };
};
