import { reactive, computed, watch } from "@vue/composition-api";
import Moment from "moment";
import store from "@/store";
import axios from "axios";
import helpers from "@/helpers";

let cancelTokenMembers;

const memberFilter = (value) => {
  filters.isMemberLoading = true;

  if (cancelTokenMembers) {
    cancelTokenMembers.cancel("Operation canceled due to new request.");
  }

  //Save the cancel token for the current request
  cancelTokenMembers = axios.CancelToken.source();
  const query =
    value && value.length ? `&searchTerm=` + encodeURIComponent(value) : "";
  axios
    .get(
      `${process.env.VUE_APP_API}/member?limit=40${query}&nameAndIdOnly=true`,
      {
        cancelTokenMembers: cancelTokenMembers.token
      }
    )
    .then((response) => {
      filters.isMemberLoading = false;
      filters.membersData = response.data.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const selectedmember = computed(() => {
  return filters.membersData.find((member) => member._id == filters.member);
});

const logFilterCurrentDate = computed(() => {
  if (store.app.getTime) {
    return store.app.getTime.toISOString();
  } else {
    return new Date().toISOString();
  }
});

const filterDefaultState = {
  createdBy: null,
  member: null,
  distributionType: null,
  subLibrary: null,
  isPdfLoading: false,
  isXlsxLoading: false,
  memberSearchKey: null,
  subLibraryLogs: false,
  stock: 0,
  orderBy: "-1",
  dateType: "month",
  logMonth: Moment(logFilterCurrentDate).format("YYYY-MM"),
  logDates: [Moment(logFilterCurrentDate).format("YYYY-MM-DD")],
  logMonthText: Moment(logFilterCurrentDate).format("MM-YYYY"),
  logDateRangeText: Moment(logFilterCurrentDate).format("DD-MM-YYYY")
};

const filters = reactive({
  ...filterDefaultState,
  logFilterCurrentDate,
  showFilterOnXs: false,
  toggleFilterOnXs: function() {
    this.showFilterOnXs = !this.showFilterOnXs;
  },
  resetFilter: function() {
    for (const key in filterDefaultState) {
      this[key] = filterDefaultState[key];
    }
  },
  searchByStock: [
    { name: "All", _id: 0 },
    { name: "Not returned", _id: 1 },
    { name: "Returned", _id: 2 }
  ],
  isMemberLoading: true,
  selectedmember,
  membersData: [],
  users: null
});

const filterQuery = computed(() => {
  if (!filters.logDates) {
    return null;
  }
  let filterQuery = "";
  filterQuery += filters.member ? `&member=${filters.member}` : "";
  filterQuery += filters.distributionType
    ? `&type=${helpers.string.kebabCase(filters.distributionType)}`
    : "";
  filterQuery += filters.subLibrary ? `&subLibrary=${filters.subLibrary}` : "";
  filterQuery += filters.createdBy ? `&createdBy=${filters.createdBy}` : "";
  filterQuery += `&dateType=${filters.dateType}`;
  filterQuery += `&stock=${filters.stock}`;
  filterQuery += `&orderBy=${filters.orderBy}`;
  filterQuery += `&subLibraryOnly=${filters.subLibraryLogs ? "true" : "false"}`;
  filterQuery +=
    filters.dateType === "month" ? `&dateMonth=${filters.logMonthText}` : "";
  if (filters.dateType === "date_range") {
    const dates = filters.logDateRangeText
      ? filters.logDateRangeText.split("~")
      : [];
    filterQuery += dates[0] ? `&dateFrom=${dates[0]}` : "";
    filterQuery += dates[1] ? `&dateTo=${dates[1]}` : "";
  }
  return filterQuery;
});

watch(
  () => filters.logMonth,
  (value) => {
    filters.logMonthText = Moment(value, "YYYY-MM").format("MM-YYYY");
  }
);

watch(
  () => filters.logDates,
  (value) => {
    const newDates = value.slice();
    if (
      newDates.length === 2 &&
      Moment(newDates[0], "YYYY-MM-DD").toDate() >
        Moment(newDates[1], "YYYY-MM-DD").toDate()
    ) {
      newDates.reverse();
    }
    filters.logDateRangeText = newDates
      .map((date) => {
        return Moment(date, "YYYY-MM-DD").format("DD-MM-YYYY");
      })
      .join("~");
  }
);

export default reactive({
  filters,
  filterQuery,
  memberFilter
});
