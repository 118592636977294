import { reactive } from "@vue/composition-api";

const state = reactive({
  formData: {
    name: "",
    email: "",
    username: "",
    regNo: "",
    address: "",
    phone: "",
    password: "",
    confirmPassword: "",
    district: "",
    pin: ""
  },
  loading: false,
  step1Done: false,
  existingUsers: [],
  existingEmails: []
});

export default reactive({
  state
});
