import { reactive } from "@vue/composition-api";

const state = reactive({
  formData: {
    name: "",
    email: "",
    username: "",
    address: "",
    phone: "",
    password: "",
    confirmPassword: "",
    district: "",
    pin: "",
    member: ""
  },
  memberSearchKey: null,
  loading: false,
  existingUsers: [],
  existingEmails: [],
  membersData: [],
  loadedAll: false,
  isLoading: false,
  isMemberLoading: false,
  newSubLibraryDialog: false,
  formValid: true,
  data: [],
  resetData: function() {
    this.data = [];
    this.loadedAll = false;
  },
  resetFormData: function() {
    const formKeys = Object.keys(this.formData);
    for (let formKey of formKeys) {
      this.formData[formKey] = "";
    }
  }
});

export default reactive({
  state
});
