import axios from "axios";
import store from "@/store";
import axiosRetry from "axios-retry";

export default () => {
  axios.interceptors.request.use(
    function(config) {
      config.withCredentials = true;
      return config;
    },
    function(error) {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.data?.scopeError) {
        store.app.showDialog(
          "Not allowed",
          error?.response?.data?.message,
          "error"
        );
      }
      if (!error.message || !error.response) {
        return Promise.reject(error);
      }
      if (error.response.status !== 401) {
        return Promise.reject(error);
      }

      store.app.logOut();
    }
  );

  axiosRetry(axios, { retries: 2 });
};
