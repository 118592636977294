import { reactive, computed, watch } from "@vue/composition-api";
import Moment from "moment";
import store from "@/store";

const state = reactive({
  loadedAll: false,
  isLoading: false,
  data: [],
  resetData: function() {
    this.data = [];
    this.loadedAll = false;
  }
});

const filterDefaultState = {
  searchKey: null,
  orderType: -1
};

const filters = reactive({
  ...filterDefaultState,
  showFilterOnXs: false,
  toggleFilterOnXs: function() {
    this.showFilterOnXs = !this.showFilterOnXs;
  },
  resetFilter: function() {
    for (const key in filterDefaultState) {
      this[key] = filterDefaultState[key];
    }
  },
  searchByTypes: [
    { name: "Newer", orderType: -1 },
    { name: "Oldest", orderType: 1 }
  ]
});

const filterQuery = computed(() => {
  let filterQuery = "";
  filterQuery +=
    filters.searchKey && filters.searchKey.length
      ? `&searchTerm=${encodeURIComponent(filters.searchKey)}`
      : "";
  filterQuery += `&orderType=${filters.orderType}`;
  return filterQuery;
});

watch(
  () => filters.billMonth,
  (value) => {
    filters.billMonthText = Moment(value, "YYYY-MM").format("MM-YYYY");
  }
);

watch(
  () => filters.billDates,
  (value) => {
    const newDates = value.slice();
    if (
      newDates.length === 2 &&
      Moment(newDates[0], "YYYY-MM-DD").toDate() >
        Moment(newDates[1], "YYYY-MM-DD").toDate()
    ) {
      newDates.reverse();
    }
    filters.billDateRangeText = newDates
      .map((date) => {
        return Moment(date, "YYYY-MM-DD").format("DD-MM-YYYY");
      })
      .join("~");
  }
);

export default reactive({
  state,
  filters,
  filterQuery
});
